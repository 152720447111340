import Actions from '../Types';

const initialState = {
  isGettingCategories: false,
  getAllCategorySuccess: false,
  getCategorySuccess: false,
  categories: [],
  allcategories: [],
  categoriesData: {},
  isSearchingCategories: false,
  searchCategorySuccess: false,
  searchedCategories: [],
  searchedCategoriesData: {},
  isAddingCategory: false,
  addSuccess: false,
  isGettingSingle: false,
  getSuccess: false,
  singleCategory: {},
  isEditingCategory: false,
  editSuccess: false,
  isDeletingCategory: false,
  deleteSuccess: false,
  category: {},
  error: null,
  isPushingToTop: false,
  pushToTopSuccess: false
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_APPOINTMENT_CATEGORIES_START:
      return {
        ...state,
        isGettingCategories: true,
        getCategorySuccess: false,
        categories: [],
        error: null,
      };
    case Actions.GET_APPOINTMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isGettingCategories: false,
        getCategorySuccess: true,
        categories: action.payload,
        error: null,
      };
    case Actions.GET_APPOINTMENT_CATEGORIES_FAILURE:
      return {
        ...state,
        isGettingCategories: false,
        getCategorySuccess: false,
        categories: [],
        error: action.payload,
      };
    case Actions.GET_ALL_APPOINTMENT_CATEGORIES_START:
      return {
        ...state,
        isGettingCategories: true,
        getAllCategorySuccess: false,
        searchCategorySuccess: false,
        addSuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        allcategories: [],
        categoriesData: {},
        error: null,
      };
    case Actions.GET_ALL_APPOINTMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isGettingCategories: false,
        getAllCategorySuccess: true,
        searchCategorySuccess: false,
        deleteSuccess: false,
        pushToTopSuccess: false,
        editSuccess: false,
        allcategories: action.payload && action.payload.rows,
        categoriesData: action.payload,
        error: null,
      };
    case Actions.GET_ALL_APPOINTMENT_CATEGORIES_FAILURE:
      return {
        ...state,
        isGettingCategories: false,
        getAllCategorySuccess: false,
        searchCategorySuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        allcategories: [],
        categoriesData: {},
        error: action.payload,
      };
    case Actions.SEARCH_APPOINTMENT_CATEGORIES_START:
      return {
        ...state,
        isSearchingCategories: true,
        searchCategorySuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        searchedCategories: [],
        searchedCategoriesData: {},
        error: null,
      };
    case Actions.SEARCH_APPOINTMENT_CATEGORIES_SUCCESS:
      return {
        ...state,
        isSearchingCategories: false,
        searchCategorySuccess: true,
        deleteSuccess: false,
        editSuccess: false,
        searchedCategories: action.payload && action.payload.rows,
        searchedCategoriesData: action.payload,
        error: null,
      };
    case Actions.SEARCH_APPOINTMENT_CATEGORIES_FAILURE:
      return {
        ...state,
        isSearchingCategories: false,
        searchCategorySuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        searchedCategories: [],
        searchedCategoriesData: {},
        error: action.payload,
      };
    case Actions.ADD_APPOINTMENT_CATEGORY_START:
      return {
        ...state,
        isAddingCategory: true,
        addSuccess: false,
        category: {},
        error: null,
      };
    case Actions.ADD_APPOINTMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        isAddingCategory: false,
        addSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.ADD_APPOINTMENT_CATEGORY_FAILURE:
      return {
        ...state,
        isAddingCategory: false,
        addSuccess: false,
        category: {},
        error: action.payload,
      };
    case Actions.GET_SINGLE_APPOINTMENT_CATEGORY_START:
      return {
        ...state,
        isGettingSingle: true,
        getSuccess: false,
        singleCategory: {},
        error: null,
      };
    case Actions.GET_SINGLE_APPOINTMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        isGettingSingle: false,
        getSuccess: true,
        singleCategory: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_APPOINTMENT_CATEGORY_FAILURE:
      return {
        ...state,
        isGettingSingle: false,
        getSuccess: false,
        singleCategory: {},
        error: action.payload,
      };
    case Actions.EDIT_APPOINTMENT_CATEGORY_START:
      return {
        ...state,
        isEditingCategory: true,
        editSuccess: false,
        category: {},
        error: null,
      };
    case Actions.EDIT_APPOINTMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        isEditingCategory: false,
        editSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.EDIT_APPOINTMENT_CATEGORY_FAILURE:
      return {
        ...state,
        isEditingCategory: false,
        editSuccess: false,
        category: {},
        error: action.payload,
      };
    case Actions.DELETE_APPOINTMENT_CATEGORY_START:
      return {
        ...state,
        isDeletingCategory: true,
        deleteSuccess: false,
        category: {},
        error: null,
      };
    case Actions.DELETE_APPOINTMENT_CATEGORY_SUCCESS:
      return {
        ...state,
        isDeletingCategory: false,
        deleteSuccess: true,
        category: action.payload,
        error: null,
      };
    case Actions.DELETE_APPOINTMENT_CATEGORY_FAILURE:
      return {
        ...state,
        isDeletingCategory: false,
        deleteSuccess: false,
        category: {},
        error: action.payload,
      };
    case Actions.PUSH_APPOINTMEMT_TO_TOP_START:
      return {
        ...state,
        isPushingToTop: true,
        pushToTopSuccess: false
      }
    case Actions.PUSH_APPOINTMEMT_TO_TOP_SUCCESS:
      return {
        ...state,
        isPushingToTop: false,
        pushToTopSuccess: true
      }
    default:
      return state;
  }
};

export default reduxState;

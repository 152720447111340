import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { PageButton } from "../../components/Button/Button";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import Calender from "../../assets/calendar-appoint.svg";
import AppointmentHeader from "../../components/Appointments/AppointmentHeader";
import Grid from "../../components/Appointments/Grid";
import List from "../../components/Appointments/List";
import ScheduleAppointment from "../../components/Appointments/ScheduleAppointment";
import {
  exportAppointmentData,
  filterUserAppointments,
  getUserAppointments,
  scheduleUserAppointment,
  searchUserAppointments,
} from "../../redux/Actions/UserAppointmentActions";
import { connect } from "react-redux";
import FilterAppointment from "../../components/Appointments/FilterAppointment";
import TableDropdown from "../../components/Tables/Dropdown";
import downloadCSV from "../../utils/functions/csvDownloader";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import moment from "moment";
import { LoaderSpin } from "../../components/Loader/Loader";
import responseHandler from "../../utils/notifications/responseHandler";
import Alert from "../../utils/notifications/Alert";
import MiniDropdown from "../../components/Overview/MiniDropdown";
import CustomDropdown from "../../components/CustomDropdown";
import { DateRangePicker } from "react-date-range";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";
import OptionIcon from "../../assets/option_icon.svg";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const Appointment = (props) => {
  const {
    isGetting,
    getSuccess,
    appointments,
    appointmentsData,
    isFiltering,
    filterSuccess,
    filteredAppointments,
    filteredAppointmentsData,
    isSearching,
    searchSuccess,
    searchedAppointments,
    searchedAppointmentsData,
    getAppointments,
    searchAppointments,
    filterAppointments,
    isScheduling,
    scheduleSuccess,
    scheduleUserAppointment,
    exportAppointmentDataHandler,
    isLoadingExportData,
  } = props;

  const [state, setState] = useState({
    declineModal: false,
    deleteModal: false,
    filterModal: false,
    scheduleModal: false,
    index: 0,
    id: "",
    active: true,
    values: {},
    filterValues: {},
    searchValue: "",
  });

  const [generalData, setGeneralData] = useState([]);
  const [show, setShow] = useState(false);
  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);
  const [seasonsTabs, setSeasonsTabs] = useState(["yearly", "monthly"]);
  const [activeDropdown, setActiveDropdown] = useState("yearly");
  const overviewType = useOverviewTypeContext();
  const setOverviewType = overviewType?.setOverviewType;
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [rangeSelected, setRangeSelected] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const types = ["yearly", "monthly"];

  const searchAllAppointments = () => {
    let limit = state.index === 0 ? 6 : 10;
    if (state.searchValue === "") {
      return appointments;
    } else {
      searchAppointments(1, limit, state.searchValue);
    }
  };

  const getAllAppointments = () => {
    let limit = state.index === 0 ? 6 : 10;
    setState({
      ...state,
      searchValue: "",
    });
    getAppointments(1, limit);
  };

  const paginateAppointmentFilter = (page, perPage, values) => {
    // console.log(values);
    const payload = {
      start_date: values.start_date,
      status: values.status,
      end_date: values.end_date,
    };
    filterAppointments(page, perPage, payload);
  };

  const formatMonths = () => {
    return months.map((month, index) => ({ label: month, value: index }));
  };

  const refetchOverview = (queries, type) => {
    setState({
      ...state,
      filterValues: {
        start_date: queries.start_date,
        end_date: queries.end_date,
      },
    });
    filterAppointments(1, 10, {
      start_date: queries.start_date,
      end_date: queries.end_date,
    });
  };

  const tabOptions = [
    // Yearly
    Array.from({ length: 5 }, (_, index) => new Date().getFullYear() - index)
      .reverse()
      .map((year) => ({ label: year, value: year })),
    // Monthly
    new Date().getFullYear() !== selectedYear
      ? formatMonths()
      : formatMonths().filter((_, index) => index <= new Date().getMonth()),
  ];

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchAppointments(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateAppointmentFilter(page, perPage, state.filterValues)
      : getAppointments(page, perPage);
  };

  const dropdownHandler = (option, index, type) => {
    if (type === "yearly") {
      refetchOverview({ year: option.value }, type.replace("ly", ""));
      setOverviewType({ year: option.value, type: type.replace("ly", "") });
      setSelectedYear(option.value);
    }

    if (type === "monthly") {
      refetchOverview(
        { year: selectedYear, month: option.value + 1 },
        type.replace("ly", "")
      );
      setOverviewType({
        year: selectedYear,
        month: option.value + 1,
        type: type.replace("ly", ""),
      });
    }

    const tabs = [...seasonsTabs];
    tabs[index] = option.label;
    setSeasonsTabs(tabs);
    setActiveDropdown(option.label);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, "0");

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  const rangeFilterHandler = () => {
    const { startDate, endDate } = selectionRange[0];
    const start_date = formatDate(startDate);
    const end_date = formatDate(endDate);
    setActiveDropdown("range");
    refetchOverview({ start_date, end_date }, "week");
    setOverviewType({ start_date, end_date, type: "week" });
    setRangeSelected((range) => !range);
    setSelectedYear(new Date().getFullYear());
    setSeasonsTabs(["yearly", "monthly"]);
  };

  useEffect(
    () => {
      if (scheduleSuccess) {
        getAppointments(1, 10);
      }
    },
    // eslint-disable-next-line
    [scheduleSuccess]
  );
  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        if (
          !state.filterValues.start_date &&
          !state.filterValues.end_date &&
          !state.filterValues.status
        ) {
          return Alert(
            "error",
            "Failed",
            "",
            "Please Select Filters before Generating CSV"
          );
        }
        exportAppointmentDataHandler(
          state.filterValues?.start_date,
          state.filterValues?.end_date,
          state.filterValues?.status
        );
        return;
      default:
        return;
    }
  };

  const customRangeToggler = (
    <div className={`mini__tab${activeDropdown === "range" ? " active" : ""}`}>
      <div>
        <img src={OptionIcon} alt="option-icon" />
      </div>
    </div>
  );

  const export_options = [
    {
      name: isLoadingExportData ? <LoaderSpin /> : "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  return (
    <div>
      <HeaderWrapper
        title={"User Appointment"}
        subtitle={"View all new and done appointments from users"}
      >
        <div className="overview-header-right">
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="overview-header-right-button"
          >
            <PageButton
              width={"156px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              marginRight={"10px"}
              onClick={() => openModal("scheduleModal")}
              type={"submit"}
            >
              <div className="button-data">
                <img src={Calender} alt="schedule-icon" />
                <span>Schedule</span>
              </div>
            </PageButton>
            <PageButton
              width={"121px"}
              mainWidth={"144px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              type={"button"}
              onClick={() => setShow((prevCheck) => !prevCheck)}
            >
              <div className="button-data">
                <img src={ExportIcon} alt="export icon" />
                <span className="span2">Export</span>
                <img src={DropIcon} alt="dropdown" />
              </div>
            </PageButton>
            {show && (
              <TableDropdown
                options={export_options}
                hideDropdown={() => setShow((prevCheck) => !prevCheck)}
                active
              />
            )}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "3.2rem",
                marginTop: "3rem",
              }}
            >
              {/* {seasonsTabs.map((tab, index) => (
                  <MiniDropdown
                    key={index}
                    active={activeDropdown === tab}
                    label={tab}
                    isDropdown
                    options={tabOptions[index]}
                    onSelect={(option) =>
                      dropdownHandler(option, index, types[index])
                    }
                  />
                ))} */}
              <h1
                className=""
                style={{
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                  marginRight: "1rem",
                }}
              >
                DATE RANGE:
              </h1>
              <CustomDropdown
                tab={customRangeToggler}
                optionSelected={rangeSelected}
              >
                <DateRangePicker
                  className="date-range-picker"
                  ranges={selectionRange}
                  onChange={({ selection }) => setSelectionRange([selection])}
                />
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    onClick={() => rangeFilterHandler()}
                    className="date-range-picker-btn"
                  >
                    SELECT RANGE
                  </button>
                </div>
              </CustomDropdown>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <AppointmentHeader
        state={state}
        setState={setState}
        searchItems={searchAllAppointments}
        openModal={openModal}
        sortSuccess={searchSuccess || filterSuccess}
        refreshFunction={getAllAppointments}
      />

      {state.index === 0 ? (
        <Grid
          closeModal={closeModal}
          openModal={openModal}
          isGetting={isGetting}
          isSearching={isSearching}
          isFiltering={isFiltering}
          getSuccess={getSuccess}
          appointments={appointments}
          appointmentsData={appointmentsData}
          searchSuccess={searchSuccess}
          searchedAppointments={searchedAppointments}
          searchedAppointmentsData={searchedAppointmentsData}
          filterSuccess={filterSuccess}
          filteredAppointments={filteredAppointments}
          filteredAppointmentsData={filteredAppointmentsData}
          getAppointments={getAppointments}
          getMoreData={moreData}
          state={state}
          setState={setState}
          setGeneralData={setGeneralData}
        />
      ) : (
        <List
          isGetting={isGetting}
          isSearching={isSearching}
          isFiltering={isFiltering}
          getSuccess={getSuccess}
          appointments={appointments}
          setGeneralData={setGeneralData}
          appointmentsData={appointmentsData}
          searchSuccess={searchSuccess}
          searchedAppointments={searchedAppointments}
          searchedAppointmentsData={searchedAppointmentsData}
          filterSuccess={filterSuccess}
          filteredAppointments={filteredAppointments}
          filteredAppointmentsData={filteredAppointmentsData}
          getAppointments={getAppointments}
          moreData={moreData}
        />
      )}

      <ScheduleAppointment
        displayModal={state.scheduleModal}
        closeModal={() => closeModal("scheduleModal")}
        isScheduling={isScheduling}
        scheduleSuccess={scheduleSuccess}
        scheduleAppointment={scheduleUserAppointment}
      />

      <FilterAppointment
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFiltering}
        filterSuccess={filterSuccess}
        filterAppointment={filterAppointments}
        setFilterState={setState}
        filterState={state}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.userappointment.isGettingAppointments,
  getSuccess: state.userappointment.getSuccess,
  appointments: state.userappointment.appointments,
  appointmentsData: state.userappointment.appointmentsData,
  isFiltering: state.userappointment.isFilteringAppointments,
  filterSuccess: state.userappointment.filterSuccess,
  filteredAppointments: state.userappointment.filteredAppointments,
  filteredAppointmentsData: state.userappointment.filteredAppointmentsData,
  isSearching: state.userappointment.isSearchingAppointments,
  searchSuccess: state.userappointment.searchSuccess,
  searchedAppointments: state.userappointment.searchedAppointments,
  searchedAppointmentsData: state.userappointment.searchedAppointmentsData,
  isScheduling: state.userappointment.isScheduling,
  scheduleSuccess: state.userappointment.scheduleSuccess,
  isLoadingExportData: state.userappointment.isLoadingExportData,
});

const mapDispatchToProps = (dispatch) => ({
  getAppointments: (page, limit) => dispatch(getUserAppointments(page, limit)),
  searchAppointments: (page, limit, searchValue) =>
    dispatch(searchUserAppointments(page, limit, searchValue)),
  filterAppointments: (page, limit, payload) =>
    dispatch(filterUserAppointments(page, limit, payload)),
  scheduleUserAppointment: (payload) =>
    dispatch(scheduleUserAppointment(payload)),
  exportAppointmentDataHandler: (start_date, end_date, status) =>
    dispatch(exportAppointmentData(start_date, end_date, status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);

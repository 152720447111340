import React, { useEffect, useState } from "react";
import "jspdf-autotable";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import ClientsColumns from "../../components/TableData/Users/Clients/Clients";
import SortData from "../../components/Sorting/SortData";
import { PageButton } from "../../components/Button/Button";
import ExportIcon from "../../assets/Union.svg";
import Add from "../../assets/add.svg";
import DropIcon from "../../assets/drop-export.svg";
import AddClient from "../../components/UserManagement/Clients/AddClient";
import AddWd from "../../components/UserManagement/WhispaDoctors/AddWd";
import AddConsultant from "../../components/UserManagement/Consultants/AddConsultant";
import HfcpColumns from "../../components/TableData/Users/HFCP/Hfcp";
import WhispaDoctorColumns from "../../components/TableData/Users/WhispaDoctor/WhispaDoctor";
import TableDropdown from "../../components/Tables/Dropdown";
import AddHfcp from "../../components/UserManagement/HFCP/AddHfcp";
import {
  clearUsersState,
  filterUsers,
  getUsers,
  searchUsers,
} from "../../redux/Actions/UsersActions";
import { connect } from "react-redux";
import ConsultantColumns from "../../components/TableData/Users/Consultant/Consultant";
import html2pdf from "html2pdf.js";
import downloadCSV from "../../utils/functions/csvDownloader";
import FilterUser from "../../components/UserManagement/FilterUser";
const Users = (props) => {
  const {
    isGetting,
    getSuccess,
    users,
    usersData,
    isSearching,
    searchSuccess,
    searchedUsers,
    searchedUsersData,
    isFiltering,
    filterSuccess,
    filteredUsers,
    filteredUsersData,
    getUsers,
    searchUsers,
    filterUsers,
    createSuccess,
  } = props;
  const [state, setState] = useState({
    active1: true,
    active2: false,
    active3: false,
    active4: false,
    filterModal: false,
    addClientModal: false,
    addWdModal: false,
    addConsultantModal: false,
    addHfcpModal: false,
    searchValue: "",
    showSearchResults: false,
    filterValues: {},
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      if (createSuccess) {
        setState((state) => ({
          ...state,
          addConsultantModal: false,
          addWdModal: false,
        }));
      }
    },
    // eslint-disable-next-line
    [createSuccess]
  );

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess]
  );

  useEffect(() => {}, []);

  const [show, setShow] = useState(false);

  const allUsers = searchSuccess
    ? searchedUsers
    : filterSuccess
    ? filteredUsers
    : users;
  const exportHandler = (format) => {
    switch (format) {
      case "PDF":
        const ordersTable = document.getElementById("ordersTable");

        var opt = {
          filename: `users-${Date.now()}.pdf`,
          margin: 10,
          html2canvas: { scale: 2 },
          jsPDF: { format: "letter", orientation: "landscape" },
        };
        html2pdf().set(opt).from(ordersTable).save();
        return;
      case "CSV":
        const usersData =
          allUsers &&
          allUsers.map((user) => ({
            "First Name": user.first_name,
            "Last Name": user.last_name,
            "Email Address": user.email,
            Gender: user.gender,
            Type: user.type,
            Status: user.status,
            "Date Created": new Date(user.created_at).toLocaleDateString(),
          }));
        downloadCSV(usersData, "users-list");
        return;
      default:
        return;
    }
  };

  const options = [
    {
      name: "PDF",
      action: () => exportHandler("PDF"),
    },
    {
      name: "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  const searchAllUsers = () => {
    const checkType = state.active2
      ? "doctor"
      : state.active3
      ? "consultant"
      : state.active4
      ? "hfcp"
      : "customer";
    if (state.searchValue === "") {
      return users;
    } else {
      searchUsers(1, 10, checkType, state.searchValue, false);
    }
  };

  const type = state.active1
    ? "customer"
    : state.active2
    ? "doctor"
    : state.active3
    ? "consultant"
    : state.active4
    ? "hfcp"
    : "";


  // useEffect(() => {
  //     console.log(allUsers)
  // }, [allUsers])

  const moreData = (page, perPage, type, hasReferredUsers) => {
    const status = state.filterValues?.status?.toLowerCase();
    const gender = state.filterValues?.gender?.toLowerCase();
    const location = state.filterValues?.location;
    const startDate = state.filterValues?.startDate;
    const endDate = state.filterValues?.endDate;
    const agegroup = state.filterValues?.age_group

    searchSuccess
      ? searchUsers(page, perPage, type, state.searchValue, hasReferredUsers)
      : filterSuccess
      ? filterUsers(
          startDate,
          endDate,
          page,
          perPage,
          type,
          gender,
          agegroup,
          status,
          location
        )
      : getUsers(page, perPage, type, hasReferredUsers);
  };

  const totalUsers = searchSuccess
    ? searchedUsersData && searchedUsersData.count
    : filterSuccess
    ? filteredUsersData && filteredUsersData.count
    : usersData && usersData.count;

  return (
    <div>
      <HeaderWrapper
        title={"User Management"}
        subtitle={"View and Manage all Users"}
      >
        {state.active1 ? (
          <div className="overview-header-right-button">
            {/* Add Customer Button  */}
            <PageButton
              width={"auto"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginRight={"10px"}
              type={"button"}
              onClick={() => openModal("addClientModal")}
            >
              <div className="button-data">
                <img src={Add} alt="add-icon" />
                <span>Add Customer</span>
              </div>
            </PageButton>

            {/* Export Button  */}
            <PageButton
              width={"121px"}
              mainWidth={"144px"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginTop={"30px"}
              type={"button"}
              onClick={() => setShow((prevCheck) => !prevCheck)}
            >
              <div className="button-data">
                <img src={ExportIcon} alt="export icon" />
                <span className="span2">Export</span>
                <img src={DropIcon} alt="dropdown" />
              </div>
            </PageButton>
            {show && (
              <TableDropdown
                options={options}
                hideDropdown={() => setShow((prevCheck) => !prevCheck)}
                active
              />
            )}
          </div>
        ) : (
          <PageButton
            width={"auto"}
            background={"rgba(10, 116, 220, 0.08)"}
            marginTop={"30px"}
            type={"button"}
            onClick={
              state.active2
                ? () => openModal("addWdModal")
                : state.active3
                ? () => openModal("addConsultantModal")
                : () => openModal("addHfcpModal")
            }
          >
            <div className="button-data">
              <img src={Add} alt="add-icon" />
              <span>
                {state.active2
                  ? "Add WD"
                  : state.active3
                  ? "Add Consultant"
                  : "Add HFCP"}
              </span>
            </div>
          </PageButton>
        )}
      </HeaderWrapper>
      <SwitchWrapper
        title1={"Clients"}
        title2={"Whispa Doctors"}
        title3={"Consultants"}
        title4={"HFCP"}
        state={state}
        setstate={setState}
        component1={
          <ClientsColumns
            state={state}
            setState={setState}
            data={allUsers}
            getData={(page, limit) => getUsers(page, limit, "customer", false)}
            getMoreData={(page, perPage) =>
              moreData(page, perPage, "customer", false)
            }
            isLoading={isGetting || isSearching || isFiltering}
            success={getSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            total={totalUsers}
          />
        }
        component2={
          <WhispaDoctorColumns
            state={state}
            setState={setState}
            data={allUsers}
            getData={(page, limit) => getUsers(page, limit, "doctor", false)}
            getMoreData={(page, perPage) =>
              moreData(page, perPage, "doctor", false)
            }
            isLoading={isGetting || isSearching || isFiltering}
            success={getSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            total={totalUsers}
          />
        }
        component3={
          <ConsultantColumns
            state={state}
            setState={setState}
            data={allUsers}
            getData={(page, limit) =>
              getUsers(page, limit, "consultant", false)
            }
            getMoreData={(page, perPage) =>
              moreData(page, perPage, "consultant", false)
            }
            isLoading={isGetting || isSearching || isFiltering}
            success={getSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            total={totalUsers}
          />
        }
        component4={
          <HfcpColumns
            state={state}
            setState={setState}
            data={allUsers}
            getData={(page, limit) => getUsers(page, limit, "hfcp", false)}
            getMoreData={(page, perPage) =>
              moreData(page, perPage, "hfcp", false)
            }
            isLoading={isGetting || isSearching || isFiltering}
            success={getSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            total={totalUsers}
          />
        }
        right1={
          <SortData
            placeholder={"Search users"}
            width={"347px"}
            searchItems={searchAllUsers}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            filter={true}
            sortSuccess={filterSuccess || searchSuccess}
            refreshFunction={() => getUsers(1, 10, type, false)}
          />
        }
        right2={"false"}
      />

      <AddClient
        displayModal={state.addClientModal}
        closeModal={() => closeModal("addClientModal")}
        getUsers={getUsers}
      />

      <AddWd
        displayModal={state.addWdModal}
        closeModal={() => closeModal("addWdModal")}
        getUsers={getUsers}
      />

      <AddConsultant
        displayModal={state.addConsultantModal}
        closeModal={() => closeModal("addConsultantModal")}
        getUsers={getUsers}
      />

      <AddHfcp
        displayModal={state.addHfcpModal}
        closeModal={() => closeModal("addHfcpModal")}
        getUsers={getUsers}
      />
      <FilterUser
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        type={type}
        prevstate={state}
        setPrevState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.users.isGetting,
  getSuccess: state.users.getSuccess,
  users: state.users.users,
  usersData: state.users.usersData,
  isSearching: state.users.isSearching,
  searchSuccess: state.users.searchSuccess,
  searchedUsers: state.users.searchedUsers,
  searchedUsersData: state.users.searchedUsersData,
  isFiltering: state.users.isFiltering,
  filterSuccess: state.users.filterSuccess,
  filteredUsers: state.users.filteredUsers,
  filteredUsersData: state.users.filteredUsersData,
  createSuccess: state.users.createSuccess,
  deleteSuccess: state.users.deleteSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (page, limit, type, hasReferredUsers) =>
    dispatch(getUsers(page, limit, type, hasReferredUsers)),
  searchUsers: (page, limit, type, searchValue, hasReferredUsers) =>
    dispatch(searchUsers(page, limit, type, searchValue, hasReferredUsers)),
  filterUsers: (startDate, endDate, page, limit, type, gender, age, status) =>
    dispatch(
      filterUsers(startDate, endDate, page, limit, type, gender, age, status)
    ),
  clearState: () => dispatch(clearUsersState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);

import React, { useEffect, useState, useRef } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SortData from "../../components/Sorting/SortData";
import Table from "../../components/Tables/Table";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import FilterSubscribers from "../../components/PaymentPlans/FilterSubscribers";
import BackBtn from "../../components/Input/BackBtn";
import { connect } from "react-redux";
import Add from "../../assets/add.svg";
import {
  filterAllSubscribers,
  getAllSubscribers,
  getExportSubscribersCSVResponse,
  searchAllSubscribers,
} from "../../redux/Actions/PaymentPlanActions";
import { subscribersColumns } from "../../components/TableData/Payment/Subscribers";
import Export3 from "../../components/Overview/Export3";
import html2pdf from "html2pdf.js";
import downloadCSV from "../../utils/functions/csvDownloader";
import { PageButton } from "../../components/Button/Button";
import CreateSubscription from "../../components/Subscriptions/CreateSubscription";
import { getSubscriptions } from "../../redux/Actions/SubscriptionsAction";
import Alert from "../../utils/notifications/Alert";
import CustomDropdown from "../../components/CustomDropdown";
import { DateRangePicker } from "react-date-range";
import OptionIcon from "../../assets/option_icon.svg";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

export const paginateSubscribersFilter = (
  page,
  perPage,
  values,
  filterSubscribers
) => {
  const payload = {
    plan: values.plan,
    subStartDate: values.subStartDate,
    subEndDate: values.subEndDate,
    // startDate: values.startDate,
    // endDate: values.endDate,
  };

  filterSubscribers(page, perPage, payload);
};

const SubscribedUsers = (props) => {
  const {
    isGettingSubscribers,
    getSuccess,
    subscribers,
    subscribersData,
    isFilteringSubscribers,
    filterSuccess,
    filteredSubscribers,
    filteredSubscribersData,
    isSearchingSubscribers,
    searchSuccess,
    searchedSubscribers,
    searchedSubscribersData,
    getSubscribers,
    searchSubscribers,
    filterSubscribers,
    getCSVResponseDataSuccess,
    csvResponseData,
    getExportSubscribersCSVResponse,
    isGettingCSVResponse,
    getSubscriptions,
    subscriptiondata,
  } = props;

  let date = new Date();
  let firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [seasonsTabs, setSeasonsTabs] = useState(["yearly", "monthly"]);
  const [minDate, setMinDate] = useState(firstDayMonth.toISOString());
  const [maxDate, setMaxDate] = useState(new Date().toISOString());
  const [rangeSelected, setRangeSelected] = useState(false);
  const overviewType = useOverviewTypeContext();
  const setOverviewType = overviewType?.setOverviewType;
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [activeDropdown, setActiveDropdown] = useState("yearly");
  const [state, setState] = useState({
    filterModal: false,
    assignSubscriptionModal: false,
    filterValues: {},
    searchValue: "",

  });
  const wrapperRef = useRef();

  useEffect(() => {
    getSubscriptions();
  }, []);

  const refetchOverview = (queries, type) => {
    setState({
      ...state,
      filterValues: {
        subStartDate: queries.start_date,
        subEndDate: queries.end_date,
      },
    });
    filterSubscribers(1, 10, {
      subStartDate: queries.start_date,
      subEndDate: queries.end_date,
    });
    // filterOrders(1, 10, queries.start_date, queries.end_date);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, "0");

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  const rangeFilterHandler = () => {
    const { startDate, endDate } = selectionRange[0];
    const start_date = formatDate(startDate);
    const end_date = formatDate(endDate);
    setActiveDropdown("range");
    refetchOverview({ start_date, end_date }, "week");
    setOverviewType({ start_date, end_date, type: "week" });
    setRangeSelected((range) => !range);
    setSelectedYear(new Date().getFullYear());
    setSeasonsTabs(["yearly", "monthly"]);
  };

  useEffect(() => {
    if (subscriptiondata) {
      const planIdData = subscriptiondata.rows.map((el) => el.plan_id);
      const uniqueIds = [...new Set(planIdData)];
      const sort = uniqueIds.sort(function (a, b) {
        return a - b;
      });
      setPlanIds(sort);
      // console.log(subscriptiondata)
    }
  }, [subscriptiondata]);

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const searchAllSubscribers = () => {
    if (state.searchValue === "") {
      return subscribers;
    } else {
      searchSubscribers(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    searchSuccess
      ? searchSubscribers(page, perPage, state.searchValue)
      : filterSuccess
      ? paginateSubscribersFilter(
          page,
          perPage,
          state.filterValues,
          filterSubscribers
        )
      : getSubscribers(page, perPage);
    // getExportSubscribersCSVResponse("", page, perPage);
  };

  const [planIds, setPlanIds] = useState([]);

  const allSubscribers = searchSuccess
    ? searchedSubscribers
    : filterSuccess
    ? filteredSubscribers
    : subscribers;

  const totalSubscribers = searchSuccess
    ? searchedSubscribersData && searchedSubscribersData.total
    : filterSuccess
    ? filteredSubscribersData && filteredSubscribersData.total
    : subscribersData && subscribersData.total;

  const customRangeToggler = (
    <div className={`mini__tab${activeDropdown === "range" ? " active" : ""}`}>
      <div>
        <img src={OptionIcon} alt="option-icon" />
      </div>
    </div>
  );

  useEffect(
    () => {
      if (getSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getSuccess, minDate, maxDate]
  );
  // useEffect(() => {
  //   getExportSubscribersCSVResponse("", 1, 10);
  // }, []);

  // To export
  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        if (
          !state.filterValues.subStartDate &&
          !state.filterValues.subEndDate &&
          !state.filterValues.plan
        ) {
          return Alert(
            "error",
            "Failed",
            "",
            "Please Select Filters before Generating CSV"
          );
        }

        // if (
        //   !state.filterValues.subStartDate &&
        //   !state.filterValues.subEndDate &&
        //   state.filterValues.plan
        // ) {
        //   return Alert(
        //     "error",
        //     "Failed",
        //     "",
        //     "Can't Generate CSV For Plan Filters Only"
        //   );
        // }
        getExportSubscribersCSVResponse(
          {
            subStartDate: state.filterValues.subStartDate,
            subEndDate: state.filterValues.subEndDate,
            plan: state.filterValues.plan,
          },
          1,
          10
        );

        return;
      default:
        return;
    }
  };

  return (
    <div>
      {/* <BackBtn page={"/payment-plans"} /> */}
      <HeaderWrapper
        title={"Users Subscriptions"}
        subtitle={"View all subscribed users on the platform"}
      >
        <div className="overview-header-right">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "14rem",
            }}
            className="overview-header-right-button"
          >
            <div style={{ display: "flex" }}>
              <div className="" style={{ marginRight: "2rem" }}>
                <PageButton
                  onClick={() => openModal("createSubscriptionsModal")}
                >
                  <div className="button-data">
                    <img src={Add} alt="add-icon" />
                    <span>Create</span>
                  </div>
                </PageButton>
              </div>
              <div>
                <Export3
                  exportHandler={exportHandler}
                  isGettingCSVResponse={isGettingCSVResponse}
                />
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "3.2rem",
                }}
              >
                <h1
                  className=""
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  DATE RANGE:
                </h1>
                <CustomDropdown
                  tab={customRangeToggler}
                  optionSelected={rangeSelected}
                >
                  <DateRangePicker
                    className="date-range-picker"
                    ranges={selectionRange}
                    onChange={({ selection }) => setSelectionRange([selection])}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "relative",
                      zIndex: 1000,
                    }}
                  >
                    <button
                      onClick={() => rangeFilterHandler()}
                      className="date-range-picker-btn"
                    >
                      SELECT RANGE
                    </button>
                  </div>
                </CustomDropdown>
              </div>
            </div>
          </div>
        </div>
      </HeaderWrapper>

      <SortData
        placeholder={"Search subscribed users by name"}
        width={"347px"}
        searchItems={searchAllSubscribers}
        setState={setState}
        state={state}
        onClick={() => openModal("filterModal")}
        filter={true}
        sortSuccess={filterSuccess || searchSuccess}
        refreshFunction={() => {
          setState({ ...state, filterValues: {} });
          getSubscribers(1, 10);
        }}
      />

      <div ref={wrapperRef}>
        <Table
          columns={subscribersColumns}
          data={allSubscribers}
          getData={getSubscribers}
          emptyText1={"No subscribed users"}
          isLoading={
            isGettingSubscribers ||
            isFilteringSubscribers ||
            isSearchingSubscribers
          }
          getMoreData={(page, perPage) => moreData(page, perPage)}
          success={getSuccess || filterSuccess || searchSuccess}
          sortSuccess={searchSuccess || filterSuccess}
          total={totalSubscribers}
          emptyResultText={"Subscriber not found!"}
        />

        <CreateSubscription
          displayModal={state.createSubscriptionsModal}
          closeModal={() => closeModal("createSubscriptionsModal")}
          getSubscriptions={getSubscriptions}
          planIds={planIds}
        />

        <FilterSubscribers
          displayModal={state.filterModal}
          closeModal={() => closeModal("filterModal")}
          isFiltering={isFilteringSubscribers}
          filterSubscribers={filterSubscribers}
          filterSuccess={filterSuccess}
          setFilterState={setState}
          filterState={state}
          getExportSubscribersCSVResponse={(payload, page, limit) =>
            getExportSubscribersCSVResponse(payload, page, limit)
          }
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingSubscribers: state.plan.isGettingSubscribers,
  getSuccess: state.plan.getSubscribersSuccess,
  subscribers: state.plan.subscribers,
  subscribersData: state.plan.subscribersData,
  isFilteringSubscribers: state.plan.isFilteringSubscribers,
  filterSuccess: state.plan.filterSuccess,
  filteredSubscribers: state.plan.filteredSubscribers,
  filteredSubscribersData: state.plan.filteredSubscribersData,
  isSearchingSubscribers: state.plan.isSearchingSubscribers,
  searchSuccess: state.plan.searchSuccess,
  searchedSubscribers: state.plan.searchedSubscribers,
  searchedSubscribersData: state.plan.searchedSubscribersData,
  getCSVResponseDataSuccess: state.plan.getCSVResponseDataSuccess,
  csvResponseData: state.plan.csvResponseData,
  isGettingCSVResponse: state.plan.isGettingCSVResponse,
  subscriptiondata: state.subscriptions.subscriptiondata,
});

const mapDispatchToProps = (dispatch) => ({
  getSubscribers: (page, limit) => dispatch(getAllSubscribers(page, limit)),
  searchSubscribers: (page, limit, searchValue) =>
    dispatch(searchAllSubscribers(page, limit, searchValue)),
  filterSubscribers: (page, limit, payload) =>
    dispatch(filterAllSubscribers(page, limit, payload)),
  getExportSubscribersCSVResponse: (payload, page, limit) =>
    dispatch(getExportSubscribersCSVResponse(payload, page, limit)),
  getSubscriptions: () => dispatch(getSubscriptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscribedUsers);

const Actions = {
  RESCHEDULE_USER_APPOINTMENT_RESET: "RESCHEDULE_USER_APPOINTMENT_RESET",
  SET_CURRENT_ADMIN: "SET_CURRENT_ADMIN",
  ADMIN_LOGIN_START: "ADMIN_LOGIN_START",
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  SEND_EMAIL_ADDRESS_START: "SEND_EMAIL_ADDRESS_START",
  SEND_EMAIL_ADDRESS_SUCCESS: "SEND_EMAIL_ADDRESS_SUCCESS",
  SEND_EMAIL_ADDRESS_FAILURE: "SEND_EMAIL_ADDRESS_FAILURE",
  CHANGE_PASSWORD_START: "CHANGE_PASSWORD_START",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
  RESET_PASSWORD_START: "RESET_PASSWORD_START",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_ADMIN_PASSWORD_START: "RESET_ADMIN_PASSWORD_START",
  RESET_ADMIN_PASSWORD_SUCCESS: "RESET_ADMIN_PASSWORD_SUCCESS",
  RESET_ADMIN_PASSWORD_FAILURE: "RESET_ADMIN_PASSWORD_FAILURE",
  CREATE_ADMIN_START: "CREATE_ADMIN_START",
  CREATE_ADMIN_SUCCESS: "CREATE_ADMIN_SUCCESS",
  CREATE_ADMIN_FAILURE: "CREATE_ADMIN_FAILURE",
  EDIT_ADMIN_START: "EDIT_ADMIN_START",
  EDIT_ADMIN_SUCCESS: "EDIT_ADMIN_SUCCESS",
  EDIT_ADMIN_FAILURE: "EDIT_ADMIN_FAILURE",
  GET_ADMINS_START: "GET_ADMINS_START",
  GET_ADMINS_SUCCESS: "GET_ADMINS_SUCCESS",
  GET_ADMINS_FAILURE: "GET_ADMINS_FAILURE",
  SEARCH_ADMINS_START: "SEARCH_ADMINS_START",
  SEARCH_ADMINS_SUCCESS: "SEARCH_ADMINS_SUCCESS",
  SEARCH_ADMINS_FAILURE: "SEARCH_ADMINS_FAILURE",
  FILTER_ADMINS_START: "FILTER_ADMINS_START",
  FILTER_ADMINS_SUCCESS: "FILTER_ADMINS_SUCCESS",
  FILTER_ADMINS_FAILURE: "FILTER_ADMINS_FAILURE",
  REVOKE_ADMIN_START: "REVOKE_ADMIN_START",
  REVOKE_ADMIN_SUCCESS: "REVOKE_ADMIN_SUCCESS",
  REVOKE_ADMIN_FAILURE: "REVOKE_ADMIN_FAILURE",
  DELETE_ADMIN_START: "DELETE_ADMIN_START",
  DELETE_ADMIN_SUCCESS: "DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_FAILURE: "DELETE_ADMIN_FAILURE",
  CLEAR_ADMIN_STATE: "CLEAR_ADMIN_STATE",
  GET_ROLES_START: "GET_ROLES_START",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",
  ADMIN_LOG_OUT_START: "ADMIN_LOG_OUT_START",
  ADMIN_LOG_OUT_SUCCESS: "ADMIN_LOG_OUT_SUCCESS",
  ADMIN_LOG_OUT_FAILURE: "ADMIN_LOG_OUT_FAILURE",
  GET_OVERVIEW_START: "GET_OVERVIEW_START",
  GET_OVERVIEW_SUCCESS: "GET_OVERVIEW_SUCCESS",
  GET_OVERVIEW_FAILURE: "GET_OVERVIEW_FAILURE",
  GET_ALL_PRODUCTS_START: "GET_ALL_PRODUCTS_START",
  GET_ALL_PRODUCTS_SUCCESS: "GET_ALL_PRODUCTS_SUCCESS",
  GET_ALL_PRODUCTS_FAILURE: "GET_ALL_PRODUCTS_FAILURE",
  GET_SINGLE_PRODUCT_START: "GET_SINGLE_PRODUCT_START",
  GET_SINGLE_PRODUCT_SUCCESS: "GET_SINGLE_PRODUCT_SUCCESS",
  GET_SINGLE_PRODUCT_FAILURE: "GET_SINGLE_PRODUCT_FAILURE",
  CREATE_PRODUCT_START: "CREATE_PRODUCT_START",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_FAILURE: "CREATE_PRODUCT_FAILURE",
  EDIT_PRODUCT_START: "EDIT_PRODUCT_START",
  EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_FAILURE: "EDIT_PRODUCT_FAILURE",
  CHANGE_PRODUCT_STATUS_START: "CHANGE_PRODUCT_STATUS_START",
  CHANGE_PRODUCT_STATUS_SUCCESS: "CHANGE_PRODUCT_STATUS_SUCCESS",
  CHANGE_PRODUCT_STATUS_FAILURE: "CHANGE_PRODUCT_STATUS_FAILURE",
  STOCK_UP_PRODUCT_START: "STOCK_UP_PRODUCT_START",
  STOCK_UP_PRODUCT_SUCCESS: "STOCK_UP_PRODUCT_SUCCESS",
  STOCK_UP_PRODUCT_FAILURE: "STOCK_UP_PRODUCT_FAILURE",
  DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  FILTER_PRODUCTS_START: "FILTER_PRODUCTS_START",
  FILTER_PRODUCTS_SUCCESS: "FILTER_PRODUCTS_SUCCESS",
  FILTER_PRODUCTS_FAILURE: "FILTER_PRODUCTS_FAILURE",
  SEARCH_PRODUCTS_START: "SEARCH_PRODUCTS_START",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "SEARCH_PRODUCTS_FAILURE",
  GET_PRODUCT_HISTORY_START: "GET_PRODUCT_HISTORY_START",
  GET_PRODUCT_HISTORY_SUCCESS: "GET_PRODUCT_HISTORY_SUCCESS",
  GET_PRODUCT_HISTORY_FAILURE: "GET_PRODUCT_HISTORY_FAILURE",
  FILTER_PRODUCT_HISTORY_START: "FILTER_PRODUCT_HISTORY_START",
  FILTER_PRODUCT_HISTORY_SUCCESS: "FILTER_PRODUCT_HISTORY_SUCCESS",
  FILTER_PRODUCT_HISTORY_FAILURE: "FILTER_PRODUCT_HISTORY_FAILURE",
  SEARCH_PRODUCT_HISTORY_START: "SEARCH_PRODUCT_HISTORY_START",
  SEARCH_PRODUCT_HISTORY_SUCCESS: "SEARCH_PRODUCT_HISTORY_SUCCESS",
  SEARCH_PRODUCT_HISTORY_FAILURE: "SEARCH_PRODUCT_HISTORY_FAILURE",
  UPDATE_PROFILE_START: "UPDATE_PROFILE_START",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  GET_FACILITIES_START: "GET_FACILITIES_START",
  GET_FACILITIES_SUCCESS: "GET_FACILITIES_SUCCESS",
  GET_FACILITIES_FAILURE: "GET_FACILITIES_FAILURE",
  GET_ALL_FACILITIES_START: "GET_ALL_FACILITIES_START",
  GET_ALL_FACILITIES_SUCCESS: "GET_ALL_FACILITIES_SUCCESS",
  GET_ALL_FACILITIES_FAILURE: "GET_ALL_FACILITIES_FAILURE",
  ADD_NEW_FACILITY_START: "ADD_NEW_FACILITY_START",
  ADD_NEW_FACILITY_SUCCESS: "ADD_NEW_FACILITY_SUCCESS",
  ADD_NEW_FACILITY_FAILURE: "ADD_NEW_FACILITY_FAILURE",
  GET_SINGLE_FACILITY_START: "GET_SINGLE_FACILITY_START",
  GET_SINGLE_FACILITY_SUCCESS: "GET_SINGLE_FACILITY_SUCCESS",
  GET_SINGLE_FACILITY_FAILURE: "GET_SINGLE_FACILITY_FAILURE",
  EDIT_FACILITY_START: "EDIT_FACILITY_START",
  EDIT_FACILITY_SUCCESS: "EDIT_FACILITY_SUCCESS",
  EDIT_FACILITY_FAILURE: "EDIT_FACILITY_FAILURE",
  CHANGE_FACILITY_STATUS_START: "CHANGE_FACILITY_STATUS_START",
  CHANGE_FACILITY_STATUS_SUCCESS: "CHANGE_FACILITY_STATUS_SUCCESS",
  CHANGE_FACILITY_STATUS_FAILURE: "CHANGE_FACILITY_STATUS_FAILURE",
  FILTER_FACILITIES_START: "FILTER_FACILITIES_START",
  FILTER_FACILITIES_SUCCESS: "FILTER_FACILITIES_SUCCESS",
  FILTER_FACILITIES_FAILURE: "FILTER_FACILITIES_FAILURE",
  SEARCH_FACILITIES_START: "SEARCH_FACILITIES_START",
  SEARCH_FACILITIES_SUCCESS: "SEARCH_FACILITIES_SUCCESS",
  SEARCH_FACILITIES_FAILURE: "SEARCH_FACILITIES_FAILURE",
  DELETE_FACILITY_START: "DELETE_FACILITY_START",
  DELETE_FACILITY_SUCCESS: "DELETE_FACILITY_SUCCESS",
  DELETE_FACILITY_FAILURE: "DELETE_FACILITY_FAILURE",
  GET_APPOINTMENT_CATEGORIES_START: "GET_APPOINTMENT_CATEGORIES_START",
  GET_APPOINTMENT_CATEGORIES_SUCCESS: "GET_APPOINTMENT_CATEGORIES_SUCCESS",
  GET_APPOINTMENT_CATEGORIES_FAILURE: "GET_APPOINTMENT_CATEGORIES_FAILURE",
  GET_ALL_APPOINTMENT_CATEGORIES_START: "GET_ALL_APPOINTMENT_CATEGORIES_START",
  GET_ALL_APPOINTMENT_CATEGORIES_SUCCESS:
    "GET_ALL_APPOINTMENT_CATEGORIES_SUCCESS",
  GET_ALL_APPOINTMENT_CATEGORIES_FAILURE:
    "GET_ALL_APPOINTMENT_CATEGORIES_FAILURE",
  SEARCH_APPOINTMENT_CATEGORIES_START: "SEARCH_APPOINTMENT_CATEGORIES_START",
  SEARCH_APPOINTMENT_CATEGORIES_SUCCESS:
    "SEARCH_APPOINTMENT_CATEGORIES_SUCCESS",
  SEARCH_APPOINTMENT_CATEGORIES_FAILURE:
    "SEARCH_APPOINTMENT_CATEGORIES_FAILURE",
  ADD_APPOINTMENT_CATEGORY_START: "ADD_APPOINTMENT_CATEGORY_START",
  ADD_APPOINTMENT_CATEGORY_SUCCESS: "ADD_APPOINTMENT_CATEGORY_SUCCESS",
  ADD_APPOINTMENT_CATEGORY_FAILURE: "ADD_APPOINTMENT_CATEGORY_FAILURE",
  GET_SINGLE_APPOINTMENT_CATEGORY_START:
    "GET_SINGLE_APPOINTMENT_CATEGORY_START",
  GET_SINGLE_APPOINTMENT_CATEGORY_SUCCESS:
    "GET_SINGLE_APPOINTMENT_CATEGORY_SUCCESS",
  GET_SINGLE_APPOINTMENT_CATEGORY_FAILURE:
    "GET_SINGLE_APPOINTMENT_CATEGORY_FAILURE",
  EDIT_APPOINTMENT_CATEGORY_START: "EDIT_APPOINTMENT_CATEGORY_START",
  EDIT_APPOINTMENT_CATEGORY_SUCCESS: "EDIT_APPOINTMENT_CATEGORY_SUCCESS",
  EDIT_APPOINTMENT_CATEGORY_FAILURE: "EDIT_APPOINTMENT_CATEGORY_FAILURE",
  DELETE_APPOINTMENT_CATEGORY_START: "DELETE_APPOINTMENT_CATEGORY_START",
  DELETE_APPOINTMENT_CATEGORY_SUCCESS: "DELETE_APPOINTMENT_CATEGORY_SUCCESS",
  DELETE_APPOINTMENT_CATEGORY_FAILURE: "DELETE_APPOINTMENT_CATEGORY_FAILURE",
  GET_SPECIALISTS_START: "GET_SPECIALISTS_START",
  GET_SPECIALISTS_SUCCESS: "GET_SPECIALISTS_SUCCESS",
  GET_SPECIALISTS_FAILURE: "GET_SPECIALISTS_FAILURE",
  GET_USER_APPOINTMENTS_START: "GET_USER_APPOINTMENTS_START",
  GET_USER_APPOINTMENTS_SUCCESS: "GET_USER_APPOINTMENTS_SUCCESS",
  GET_USER_APPOINTMENTS_FAILURE: "GET_USER_APPOINTMENTS_FAILURE",
  SEARCH_USER_APPOINTMENTS_START: "SEARCH_USER_APPOINTMENTS_START",
  SEARCH_USER_APPOINTMENTS_SUCCESS: "SEARCH_USER_APPOINTMENTS_SUCCESS",
  SEARCH_USER_APPOINTMENTS_FAILURE: "SEARCH_USER_APPOINTMENTS_FAILURE",
  FILTER_USER_APPOINTMENTS_START: "FILTER_USER_APPOINTMENTS_START",
  FILTER_USER_APPOINTMENTS_SUCCESS: "FILTER_USER_APPOINTMENTS_SUCCESS",
  FILTER_USER_APPOINTMENTS_FAILURE: "FILTER_USER_APPOINTMENTS_FAILURE",
  SCHEDULE_USER_APPOINTMENT_START: "SCHEDULE_USER_APPOINTMENT_START",
  SCHEDULE_USER_APPOINTMENT_SUCCESS: "SCHEDULE_USER_APPOINTMENTY_SUCCESS",
  SCHEDULE_USER_APPOINTMENT_FAILURE: "SCHEDULE_USER_APPOINTMENT_FAILURE",
  RESCHEDULE_USER_APPOINTMENT_START: "RESCHEDULE_USER_APPOINTMENT_START",
  RESCHEDULE_USER_APPOINTMENT_SUCCESS: "RESCHEDULE_USER_APPOINTMENT_SUCCESS",
  RESCHEDULE_USER_APPOINTMENT_FAILURE: "RESCHEDULE_USER_APPOINTMENT_FAILURE",
  DELETE_USER_APPOINTMENT_START: "DELETE_USER_APPOINTMENT_START",
  DELETE_USER_APPOINTMENT_SUCCESS: "DELETE_USER_APPOINTMENT_SUCCESS",
  DELETE_USER_APPOINTMENT_FAILURE: "DELETE_USER_APPOINTMENT_FAILURE",
  CHANGE_USER_APPOINTMENT_STATUS_START: "CHANGE_USER_APPOINTMENT_STATUS_START",
  CHANGE_USER_APPOINTMENT_STATUS_SUCCESS:
    "CHANGE_USER_APPOINTMENT_STATUS_SUCCESS",
  CHANGE_USER_APPOINTMENT_STATUS_FAILURE:
    "CHANGE_USER_APPOINTMENT_STATUS_FAILURE",
  UPLOAD_IMAGE_START: "UPLOAD_IMAGE_START",
  UPLOAD_IMAGE_SUCCESS: "UPLOAD_IMAGE_SUCCESS",
  UPLOAD_IMAGE_FAILURE: "UPLOAD_IMAGE_FAILURE",
  CREATE_ORDER_START: "CREATE_ORDER_START",
  CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
  GET_ALL_ORDERS_START: "GET_ALL_ORDERS_START",
  GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
  GET_ALL_ORDERS_FAILURE: "GET_ALL_ORDERS_FAILURE",
  GET_ORDER_START: "GET_ORDER_START",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",
  GET_ORDERS_BY_STATUS_START: "GET_ORDERS_BY_STATUS_START",
  GET_ORDERS_BY_STATUS_SUCCESS: "GET_ORDERS_BY_STATUS_SUCCESS",
  GET_ORDERS_BY_STATUS_FAILURE: "GET_ORDERS_BY_STATUS_FAILURE",
  SEARCH_ORDERS_START: "SEARCH_ORDERS_START",
  SEARCH_ORDERS_SUCCESS: "SEARCH_ORDERS_SUCCESS",
  SEARCH_ORDERS_FAILURE: "SEARCH_ORDERS_FAILURE",
  FILTER_ORDERS_START: "FILTER_ORDERS_START",
  FILTER_ORDERS_SUCCESS: "FILTER_ORDERS_SUCCESS",
  FILTER_ORDERS_FAILURE: "FILTER_ORDERS_FAILURE",
  CHANGE_ORDER_STATUS_START: "CHANGE_ORDER_STATUS_START",
  CHANGE_ORDER_STATUS_SUCCESS: "CHANGE_ORDER_STATUS_SUCCESS",
  CHANGE_ORDER_STATUS_FAILURE: "CHANGE_ORDER_STATUS_FAILURE",
  CLEAR_ORDERS_STATE: "CLEAR_ORDERS_STATE",
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  SEARCH_USERS_START: "SEARCH_USERS_START",
  SEARCH_USERS_SUCCESS: "SEARCH_USERS_SUCCESS",
  SEARCH_USERS_FAILURE: "SEARCH_USERS_FAILURE",
  FILTER_USERS_START: "FILTER_USERS_START",
  FILTER_USERS_SUCCESS: "FILTER_USERS_SUCCESS",
  FILTER_USERS_FAILURE: "FILTER_USERS_FAILURE",
  CREATE_USER_START: "CREATE_USER_START",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  REVOKE_USER_START: "REVOKE_USER_START",
  REVOKE_USER_SUCCESS: "REVOKE_USER_SUCCESS",
  REVOKE_USER_FAILURE: "REVOKE_USER_FAILURE",
  GET_USERS_PROFILE_START: "GET_USERS_PROFILE_START",
  GET_USERS_PROFILE_SUCCESS: "GET_USERS_PROFILE_SUCCESS",
  GET_USERS_PROFILE_FAILURE: "GET_USERS_PROFILE_FAILURE",
  CLEAR_USERS_STATE: "CLEAR_USERS_STATE",
  GET_PRODUCT_CATEGORIES_START: "GET_PRODUCT_CATEGORIES_START",
  GET_PRODUCT_CATEGORIES_SUCCESS: "GET_PRODUCT_CATEGORIES_SUCCESS",
  GET_PRODUCT_CATEGORIES_FAILURE: "GET_PRODUCT_CATEGORIES_FAILURE",
  SEARCH_PRODUCT_CATEGORIES_START: "SEARCH_PRODUCT_CATEGORIES_START",
  SEARCH_PRODUCT_CATEGORIES_SUCCESS: "SEARCH_PRODUCT_CATEGORIES_SUCCESS",
  SEARCH_PRODUCT_CATEGORIES_FAILURE: "SEARCH_PRODUCT_CATEGORIES_FAILURE",
  CREATE_PRODUCT_CATEGORY_START: "CREATE_PRODUCT_CATEGORY_START",
  CREATE_PRODUCT_CATEGORY_SUCCESS: "CREATE_PRODUCT_CATEGORY_SUCCESS",
  CREATE_PRODUCT_CATEGORY_FAILURE: "CREATE_PRODUCT_CATEGORY_FAILURE",
  EDIT_PRODUCT_CATEGORY_START: "EDIT_PRODUCT_CATEGORY_START",
  EDIT_PRODUCT_CATEGORY_SUCCESS: "EDIT_PRODUCT_CATEGORY_SUCCESS",
  EDIT_PRODUCT_CATEGORY_FAILURE: "EDIT_PRODUCT_CATEGORY_FAILURE",
  DELETE_PRODUCT_CATEGORY_START: "DELETE_PRODUCT_CATEGORY_START",
  DELETE_PRODUCT_CATEGORY_SUCCESS: "DELETE_PRODUCT_CATEGORY_SUCCESS",
  DELETE_PRODUCT_CATEGORY_FAILURE: "DELETE_PRODUCT_CATEGORY_FAILURE",
  CREATE_PLAN_START: "CREATE_PLAN_START",
  CREATE_PLAN_SUCCESS: "CREATE_PLAN_SUCCESS",
  CREATE_PLAN_FAILURE: "CREATE_PLAN_FAILURE",
  EDIT_PLAN_START: "EDIT_PLAN_START",
  EDIT_PLAN_SUCCESS: "EDIT_PLAN_SUCCESS",
  EDIT_PLAN_FAILURE: "EDIT_PLAN_FAILURE",
  DELETE_PLAN_START: "DELETE_PLAN_START",
  DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
  DELETE_PLAN_FAILURE: "DELETE_PLAN_FAILURE",
  GET_ALL_PLANS_START: "GET_ALL_PLANS_START",
  GET_ALL_PLANS_SUCCESS: "GET_ALL_PLANS_SUCCESS",
  GET_ALL_PLANS_FAILURE: "GET_ALL_PLANS_FAILURE",
  GET_MOST_SUBSCRIBED_PLAN_START: "GET_MOST_SUBSCRIBED_PLAN_START",
  GET_MOST_SUBSCRIBED_PLAN_SUCCESS: "GET_MOST_SUBSCRIBED_PLAN_SUCCESS",
  GET_MOST_SUBSCRIBED_PLAN_FAILURE: "GET_MOST_SUBSCRIBED_PLAN_FAILURE",
  GET_ALL_SUBSCRIBERS_START: "GET_ALL_SUBSCRIBERS_START",
  GET_ALL_SUBSCRIBERS_SUCCESS: "GET_ALL_SUBSCRIBERS_SUCCESS",
  GET_ALL_SUBSCRIBERS_FAILURE: "GET_ALL_SUBSCRIBERS_FAILURE",
  FILTER_ALL_SUBSCRIBERS_START: "FILTER_ALL_SUBSCRIBERS_START",
  FILTER_ALL_SUBSCRIBERS_SUCCESS: "FILTER_ALL_SUBSCRIBERS_SUCCESS",
  FILTER_ALL_SUBSCRIBERS_FAILURE: "FILTER_ALL_SUBSCRIBERS_FAILURE",
  SEARCH_ALL_SUBSCRIBERS_START: "SEARCH_ALL_SUBSCRIBERS_START",
  SEARCH_ALL_SUBSCRIBERS_SUCCESS: "SEARCH_ALL_SUBSCRIBERS_SUCCESS",
  SEARCH_ALL_SUBSCRIBERS_FAILURE: "SEARCH_ALL_SUBSCRIBERS_FAILURE",
  CHANGE_SUBSCRIPTION_PLAN_STATUS_START:
    "CHANGE_SUBSCRIPTION_PLAN_STATUS_START",
  CHANGE_SUBSCRIPTION_PLAN_STATUS_SUCCESS:
    "CHANGE_SUBSCRIPTION_PLAN_STATUS_SUCCESS",
  CHANGE_SUBSCRIPTION_PLAN_STATUS_FAILURE:
    "CHANGE_SUBSCRIPTION_PLAN_STATUS_FAILURE",
  GET_BLOG_POSTS_START: "GET_BLOG_POSTS_START",
  GET_BLOG_POSTS_SUCCESS: "GET_BLOG_POSTS_SUCCESS",
  GET_BLOG_POSTS_FAILURE: "GET_BLOG_POSTS_FAILURE",
  CREATE_BLOG_CATEGORY_START: "CREATE_BLOG_CATEGORY_START",
  CREATE_BLOG_CATEGORY_SUCCESS: "CREATE_BLOG_CATEGORY_SUCCESS",
  CREATE_BLOG_CATEGORY_FAILURE: "CREATE_BLOG_CATEGORY_FAILURE",
  GET_BLOG_CATEGORIES_START: "GET_BLOG_CATEGORIES_START",
  GET_BLOG_CATEGORIES_SUCCESS: "GET_BLOG_CATEGORIES_SUCCESS",
  GET_BLOG_CATEGORIES_FAILURE: "GET_BLOG_CATEGORIES_FAILURE",
  GET_ALL_BLOG_CATEGORIES_START: "GET_ALL_BLOG_CATEGORIES_START",
  GET_ALL_BLOG_CATEGORIES_SUCCESS: "GET_ALL_BLOG_CATEGORIES_SUCCESS",
  GET_ALL_BLOG_CATEGORIES_FAILURE: "GET_ALL_BLOG_CATEGORIES_FAILURE",
  EDIT_BLOG_CATEGORY_START: "EDIT_BLOG_CATEGORY_START",
  EDIT_BLOG_CATEGORY_SUCCESS: "EDIT_BLOG_CATEGORY_SUCCESS",
  EDIT_BLOG_CATEGORY_FAILURE: "EDIT_BLOG_CATEGORY_FAILURE",
  SEARCH_BLOG_CATEGORIES_START: "SEARCH_BLOG_CATEGORIES_START",
  SEARCH_BLOG_CATEGORIES_SUCCESS: "SEARCH_BLOG_CATEGORIES_SUCCESS",
  SEARCH_BLOG_CATEGORIES_FAILURE: "SEARCH_BLOG_CATEGORIES_FAILURE",
  FILTER_BLOG_CATEGORIES_START: "FILTER_BLOG_CATEGORIES_START",
  FILTER_BLOG_CATEGORIES_SUCCESS: "FILTER_BLOG_CATEGORIES_SUCCESS",
  FILTER_BLOG_CATEGORIES_FAILURE: "FILTER_BLOG_CATEGORIES_FAILURE",
  CHANGE_BLOG_CATEGORY_STATUS_START: "CHANGE_BLOG_CATEGORY_STATUS_START",
  CHANGE_BLOG_CATEGORY_STATUS_SUCCESS: "CHANGE_BLOG_CATEGORY_STATUS_SUCCESS",
  CHANGE_BLOG_CATEGORY_STATUS_FAILURE: "CHANGE_BLOG_CATEGORY_STATUS_FAILURE",
  DELETE_BLOG_CATEGORY_START: "DELETE_BLOG_CATEGORY_START",
  DELETE_BLOG_CATEGORY_SUCCESS: "DELETE_BLOG_CATEGORY_SUCCESS",
  DELETE_BLOG_CATEGORY_FAILURE: "DELETE_BLOG_CATEGORY_FAILURE",
  CREATE_BLOG_POST_START: "CREATE_BLOG_POST_START",
  CREATE_BLOG_POST_SUCCESS: "CREATE_BLOG_POST_SUCCESS",
  CREATE_BLOG_POST_FAILURE: "CREATE_BLOG_POST_FAILURE",
  EDIT_BLOG_POST_START: "EDIT_BLOG_POST_START",
  EDIT_BLOG_POST_SUCCESS: "EDIT_BLOG_POST_SUCCESS",
  EDIT_BLOG_POST_FAILURE: "EDIT_BLOG_POST_FAILURE",
  GET_SINGLE_BLOG_POST_START: "GET_SINGLE_BLOG_POST_START",
  GET_SINGLE_BLOG_POST_SUCCESS: "GET_SINGLE_BLOG_POST_SUCCESS",
  GET_SINGLE_BLOG_POST_FAILURE: "GET_SINGLE_BLOG_POST_FAILURE",
  FILTER_BLOG_POSTS_START: "FILTER_BLOG_POSTS_START",
  FILTER_BLOG_POSTS_SUCCESS: "FILTER_BLOG_POSTS_SUCCESS",
  FILTER_BLOG_POSTS_FAILURE: "FILTER_BLOG_POSTS_FAILURE",
  SEARCH_BLOG_POSTS_START: "SEARCH_BLOG_POSTS_START",
  SEARCH_BLOG_POSTS_SUCCESS: "SEARCH_BLOG_POSTS_SUCCESS",
  SEARCH_BLOG_POSTS_FAILURE: "SEARCH_BLOG_POSTS_FAILURE",
  DELETE_BLOG_POST_START: "DELETE_BLOG_POST_START",
  DELETE_BLOG_POST_SUCCESS: "DELETE_BLOG_POST_SUCCESS",
  DELETE_BLOG_POST_FAILURE: "DELETE_BLOG_POST_FAILURE",
  GET_AUDIT_TRAIL_START: "GET_AUDIT_TRAIL_START",
  GET_AUDIT_TRAIL_SUCCESS: "GET_AUDIT_TRAIL_SUCCESS",
  GET_AUDIT_TRAIL_FAILURE: "GET_AUDIT_TRAIL_FAILURE",
  SEARCH_AUDIT_TRAIL_START: "SEARCH_AUDIT_TRAIL_START",
  SEARCH_AUDIT_TRAIL_SUCCESS: "SEARCH_AUDIT_TRAIL_SUCCESS",
  SEARCH_AUDIT_TRAIL_FAILURE: "SEARCH_AUDIT_TRAIL_FAILURE",
  FILTER_AUDIT_TRAIL_START: "FILTER_AUDIT_TRAIL_START",
  FILTER_AUDIT_TRAIL_SUCCESS: "FILTER_AUDIT_TRAIL_SUCCESS",
  FILTER_AUDIT_TRAIL_FAILURE: "FILTER_AUDIT_TRAIL_FAILURE",
  GET_ALL_ROLES_START: "GET_ALL_ROLES_START",
  GET_ALL_ROLES_SUCCESS: "GET_ALL_ROLES_SUCCESS",
  GET_ALL_ROLES_FAILURE: "GET_ALL_ROLES_FAILURE",
  CREATE_ROLE_START: "CREATE_ROLE_START",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",
  EDIT_ROLE_START: "EDIT_ROLE_START",
  EDIT_ROLE_SUCCESS: "EDIT_ROLE_SUCCESS",
  EDIT_ROLE_FAILURE: "EDIT_ROLE_FAILURE",
  DEACTIVATE_ROLE_START: "DEACTIVATE_ROLE_START",
  DEACTIVATE_ROLE_SUCCESS: "DEACTIVATE_ROLE_SUCCESS",
  DEACTIVATE_ROLE_FAILURE: "DEACTIVATE_ROLE_FAILURE",
  DELETE_ROLE_START: "DELETE_ROLE_START",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",
  GET_COUPONS_START: "GET_COUPONS_START",
  GET_COUPONS_SUCCESS: "GET_COUPONS_SUCCESS",
  GET_COUPONS_FAILURE: "GET_COUPONS_FAILURE",
  CREATE_COUPON_START: "CREATE_COUPON_START",
  CREATE_COUPON_SUCCESS: "CREATE_COUPON_SUCCESS",
  CREATE_COUPON_FAILURE: "CREATE_COUPON_FAILURE",
  EDIT_COUPON_START: "EDIT_COUPON_START",
  EDIT_COUPON_SUCCESS: "EDIT_COUPON_SUCCESS",
  EDIT_COUPON_FAILURE: "EDIT_COUPON_FAILURE",
  SEARCH_COUPONS_START: "SEARCH_COUPONS_START",
  SEARCH_COUPONS_SUCCESS: "SEARCH_COUPONS_SUCCESS",
  SEARCH_COUPONS_FAILURE: "SEARCH_COUPONS_FAILURE",
  FILTER_COUPONS_START: "FILTER_COUPONS_START",
  FILTER_COUPONS_SUCCESS: "FILTER_COUPONS_SUCCESS",
  FILTER_COUPONS_FAILURE: "FILTER_COUPONS_FAILURE",
  CHANGE_COUPON_STATUS_START: "CHANGE_COUPON_STATUS_START",
  CHANGE_COUPON_STATUS_SUCCESS: "CHANGE_COUPON_STATUS_SUCCESS",
  CHANGE_COUPON_STATUS_FAILURE: "CHANGE_COUPON_STATUS_FAILURE",
  GET_SINGLE_COUPON_START: "GET_SINGLE_COUPON_START",
  GET_SINGLE_COUPON_SUCCESS: "GET_SINGLE_COUPON_SUCCESS",
  DELETE_COUPON_START: "DELETE_COUPON_START",
  DELETE_COUPON_SUCCESS: "DELETE_COUPON_SUCCESS",
  DELETE_COUPON_FAILURE: "DELETE_COUPON_FAILURE",
  GET_REFERRALS_START: "GET_REFERRALS_START",
  GET_REFERRALS_SUCCESS: "GET_REFERRALS_SUCCESS",
  GET_REFERRALS_FAILURE: "GET_REFERRALS_FAILURE",
  CREATE_REFERRAL_START: "CREATE_REFERRAL_START",
  CREATE_REFERRAL_SUCCESS: "CREATE_REFERRAL_SUCCESS",
  CREATE_REFERRAL_FAILURE: "CREATE_REFERRAL_FAILURE",
  CHANGE_REFERRAL_STATUS_START: "CHANGE_REFERRAL_STATUS_START",
  CHANGE_REFERRAL_STATUS_SUCCESS: "CHANGE_REFERRAL_STATUS_SUCCESS",
  CHANGE_REFERRAL_STATUS_FAILURE: "CHANGE_REFERRAL_STATUS_FAILURE",
  EDIT_REFERRAL_START: "EDIT_REFERRAL_START",
  EDIT_REFERRAL_SUCCESS: "EDIT_REFERRAL_SUCCESS",
  EDIT_REFERRAL_FAILURE: "EDIT_REFERRAL_FAILURE",
  DELETE_REFERRAL_START: "DELETE_REFERRAL_START",
  DELETE_REFERRAL_SUCCESS: "DELETE_REFERRAL_SUCCESS",
  DELETE_REFERRAL_FAILURE: "DELETE_REFERRAL_FAILURE",
  FILTER_REFERRAL_COUPON_START: "FILTER_REFERRAL_COUPON_START",
  FILTER_REFERRAL_COUPON_SUCCESS: "FILTER_REFERRAL_COUPON_SUCCESS",
  FILTER_REFERRAL_COUPON_FAILURE: "FILTER_REFERRAL_COUPON_FAILURE",
  SINGLE_BLOG_POST_CLEAR: "SINGLE_BLOG_POST_CLEAR",
  GET_ASSESSMENTS_START: "GET_ASSESSMENTS_START",
  GET_ASSESSMENTS_SUCCESS: "GET_ASSESSMENTS_SUCCESS",
  GET_ASSESSMENTS_FAILURE: "GET_ASSESSMENTS_FAILURE",
  CREATE_QUESTION_START: "CREATE_QUESTION_START",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
  CREATE_QUESTION_FAILURE: "CREATE_QUESTION_FAILURE",
  UPDATE_ASSESSMENT_START: "UPDATE_ASSESSMENT_START",
  UPDATE_ASSESSMENT_SUCCESS: "UPDATE_ASSESSMENT_SUCCESS",
  UPDATE_ASSESSMENT_FAILURE: "UPDATE_ASSESSMENT_FAILURE",
  REORDER_ASSESSMENT_START: "REORDER_ASSESSMENT_START",
  REORDER_ASSESSMENT_SUCCESS: "REORDER_ASSESSMENT_SUCCESS",
  REORDER_ASSESSMENT_FAILURE: "REORDER_ASSESSMENT_FAILURE",
  GET_QUESTION_START: "GET_QUESTION_START",
  GET_QUESTION_SUCCESS: "GET_QUESTION_SUCCESS",
  GET_QUESTION_FAILURE: "GET_QUESTION_FAILURE",
  EDIT_QUESTION_START: "EDIT_QUESTION_START",
  EDIT_QUESTION_SUCCESS: "EDIT_QUESTION_SUCCESS",
  EDIT_QUESTION_FAILURE: "EDIT_QUESTION_FAILURE",
  RESET_QUESTION: "RESET_QUESTION",
  FILTER_ASSESSMENT_FAILURE: "FILTER_ASSESSMENT_FAILURE",
  GET_USER_CHAT_HISTORY_START: "GET_USER_CHAT_HISTORY_START",
  GET_USER_CHAT_HISTORY_SUCCESS: "GET_USER_CHAT_HISTORY_SUCCESS",
  GET_USER_CHAT_HISTORY_FAILURE: "GET_USER_CHAT_HISTORY_FAILURE",
  SEARCH_USER_CHAT_HISTORY_START: "SEARCH_USER_CHAT_HISTORY_START",
  SEARCH_USER_CHAT_HISTORY_SUCCESS: "SEARCH_USER_CHAT_HISTORY_SUCCESS",
  SEARCH_USER_CHAT_HISTORY_FAILURE: "SEARCH_USER_CHAT_HISTORY_FAILURE",
  GET_CHATS_ROOMS_START: "GET_CHATS_ROOMS_START",
  GET_CHATS_ROOMS_SUCCESS: "GET_CHATS_ROOMS_SUCCESS",
  GET_CHATS_ROOMS_FAILURE: "GET_CHATS_ROOMS_FAILURE",
  SEARCH_CHATS_ROOMS_START: "SEARCH_CHATS_ROOMS_START",
  SEARCH_CHATS_ROOMS_SUCCESS: "SEARCH_CHATS_ROOMS_SUCCESS",
  SEARCH_CHATS_ROOMS_FAILURE: "SEARCH_CHATS_ROOMS_FAILURE",
  ASSIGN_PENDING_CHAT_START: "ASSIGN_PENDING_CHAT_START",
  ASSIGN_PENDING_CHAT_SUCCESS: "ASSIGN_PENDING_CHAT_SUCCESS",
  ASSIGN_PENDING_CHAT_FAILURE: "ASSIGN_PENDING_CHAT_FAILURE",
  TRANSFER_USER_CHAT_START: "TRANSFER_USER_CHAT_START",
  TRANSFER_USER_CHAT_SUCCESS: "TRANSFER_USER_CHAT_SUCCESS",
  TRANSFER_USER_CHAT_FAILURE: "TRANSFER_USER_CHAT_FAILURE",
  FILTER_ASSESSMENT_BY_SUBCATEGORY_START:
    "FILTER_ASSESSMENT_BY_SUBCATEGORY_START",
  FILTER_ASSESSMENT_BY_SUBCATEGORY_SUCCESS:
    "FILTER_ASSESSMENT_BY_SUBCATEGORY_SUCCESS",
  FILTER_ASSESSMENT_BY_SUBCATEGORY_FAILURE:
    "FILTER_ASSESSMENT_BY_SUBCATEGORY_FAILURE",
  DELETE_ASSESSMENT_START: "DELETE_ASSESSMENT_START",
  DELETE_ASSESSMENT_SUCCESS: "DELETE_ASSESSMENT_SUCCESS",
  DELETE_ASSESSMENT_FAILURE: "DELETE_ASSESSMENT_FAILURE",
  CONFIRM_USER_APPOINTMENT_START: "CONFIRM_USER_APPOINTMENT_START",
  CONFIRM_USER_APPOINTMENT_SUCCESS: "CONFIRM_USER_APPOINTMENT_SUCCESS",
  CONFIRM_USER_APPOINTMENT_FAILURE: "CONFIRM_USER_APPOINTMENT_FAILURE",

  GET_DELIVERY_FEES_START: "GET_DELIVERY_FEES_START",
  GET_DELIVERY_FEES_SUCCESS: "GET_DELIVERY_FEES_SUCCESS",
  GET_DELIVERY_FEES_FAILURE: "GET_DELIVERY_FEES_FAILURE",
  CREATE_DELIVERY_FEE_START: "CREATE_DELIVERY_FEE_START",
  CREATE_DELIVERY_FEE_SUCCESS: "CREATE_DELIVERY_FEE_SUCCESS",
  CREATE_DELIVERY_FEE_FAILURE: "CREATE_DELIVERY_FEE_FAILURE",
  EDIT_DELIVERY_FEE_START: "EDIT_DELIVERY_FEE_START",
  EDIT_DELIVERY_FEE_SUCCESS: "EDIT_DELIVERY_FEE_SUCCESS",
  EDIT_DELIVERY_FEE_FAILURE: "EDIT_DELIVERY_FEE_FAILURE",
  DELETE_DELIVERY_FEE_START: "DELETE_DELIVERY_FEE_START",
  DELETE_DELIVERY_FEE_SUCCESS: "DELETE_DELIVERY_FEE_SUCCESS",
  DELETE_DELIVERY_FEE_FAILURE: "DELETE_DELIVERY_FEE_FAILURE",

  GET_EXPORT_CSV_SUCCESS: "GET_EXPORT_CSV_SUCCESS",
  GET_CSV_START: "GET_CSV_START",

  GET_SUBSCRIPTIONS_START: "GET_SUBSCRIPTIONS_START",
  GET_SUBSCRIPTIONS_SUCCESS: "GET_SUBSCRPTIONS_SUCCESS",

  CREATE_SUBSCRIPTIONS_START: "CREATE_SUBSCRIPTIONS_START",
  CREATE_SUBSCRIPTIONS_SUCCESS: "CREATE_SUBSCRIPTIONS_SUCCESS",
  CREATE_SUBSCRIPTIONS_FAILURE: "CREATE_SUBSCRIPTIONS_FAILURE",

  EDIT_SUBSCRIPTIONS_SUCCESS: "EDIT_SUBSCRIPTIONS_SUCCESS",
  EDIT_SUBSCRIPTIONS_FAILURE: "EDIT_SUBSCRIPTIONS_FAILURE",
  EDIT_SUBSCRIPTIONS_START: "EDIT_SUBSCRIPTIONS_START",

  ASSIGN_SUBSCRIPTION_START: "ASSIGN_SUBSCRIPTION_START",
  ASSIGN_SUBSCRIPTION_SUCCESS: "ASSIGN_SUBSCRIPTION_SUCCESS",
  ASSIGN_SUBSCRIPTION_FAILURE: "ASSIGN_SUBSCRIPTION_FAILURE",

  PUSH_APPOINTMEMT_TO_TOP_START: "PUSH_APPOINTMEMT_TO_TOP_START",
  PUSH_APPOINTMEMT_TO_TOP_SUCCESS: "PUSH_APPOINTMEMT_TO_TOP_SUCCESS",

  PUSH_PRODUCT_TO_TOP_START: "PUSH_APPOINTMEMT_TO_TOP_START",
  PUSH_PRODUCT_TO_TOP_SUCCESS: "PUSH_APPOINTMEMT_TO_TOP_SUCCESS",

  GET_ALL_PRESCRIPTIONS_START: "GET_ALL_PRESCRIPTIONS_START",
  GET_ALL_PRESCRIPTIONS_SUCCESS: "GET_ALL_PRESCRIPTIONS_SUCCESS",

  GET_PRESCRIPTION_START: "GET_PRESCRIPTION_START",
  GET_PRESCRIPTION_SUCCESS: "GET_PRESCRIPTION_SUCCESS",

  FILTER_PRESCRIPTIONS_START: "FILTER_PRESCRIPTIONS_START",
  FILTER_PRESCRIPTIONS_SUCCESS: "FILTER_PRESCRIPTIONS_SUCCESS",

  SEARCH_PRESCRIPTIONS_START: "SEARCH_PRESCRIPTIONS_START",
  SEARCH_PRESCRIPTIONS_SUCCESS: "SEARCH_PRESCRIPTIONS_SUCCESS",

  GET_ENTERPRISES_START: "GET_ENTERPRISES_START",
  GET_ENTERPRISES_SUCCESS: "GET_ENTERPRISES_SUCCESS",

  GET_SINGLE_ENTERPRISES_START: "GET_SINGLE_ENTERPRISES_START",
  GET_SINGLE_ENTERPRISES_SUCCESS: "GET_SINGLE_ENTERPRISES_SUCCESS",

  CREATE_ENTERPRISE_START: "CREATE_ENTERPRISE_START",
  CREATE_ENTERPRISE_SUCCESS: "CREATE_ENTERPRISE_SUCCESS",
  CREATE_ENTERPRISE_FAILURE: "CREATE_ENTERPRISE_FAILURE",

  UPDATE_ENTERPRISE_START: "UPDATE_ENTERPRISES_START",
  UPDATE_ENTERPRISE_SUCCESS: "UPDATE_ENTERPRISES_SUCCESS",
  UPDATE_ENTERPRISE_FAILURE: "UPDATE_ENTERPRISES_FAILURE",

  UPLOAD_ENTERPRISE_USERS_START: "UPLOAD_ENTERPRISE_USERS_START",
  UPLOAD_ENTERPRISE_USERS_SUCCESS: "UPLOAD_ENTERPRISE_USERS_SUCCESS",
  UPLOAD_ENTERPRISE_USERS_FAILURE: "UPLOAD_ENTERPRISE_USERS_FAILURE",

  GET_APP_VERSIONS_START: "GET_APP_VERSIONS_START",
  GET_APP_VERSIONS_SUCCESS: "GET_APP_VERSIONS_SUCCESS",

  UPDATE_APP_VERSIONS_START: "UPDATE_APP_VERSIONS_START",
  UPDATE_APP_VERSIONS_SUCCESS: "UPDATE_APP_VERSIONS_SUCCESS",
  UPDATE_APP_VERSIONS_FAILURE: "UPDATE_APP_VERSIONS_FAILURE",

  GET_ALL_CUSTOMERS_START: "GET_ALL_CUSTOMERS_START",
  GET_ALL_CUSTOMERS_SUCCESS: "GET_ALL_CUSTOMERS_SUCCESS",

  SEARCH_CUSTOMERS_START: "SEARCH_CUSTOMERS_START",
  SEARCH_CUSTOMERS_SUCCESS: "SEARCH_CUSTOMERS_SUCCESS",

  FILTER_CUSTOMERS_START: "FILTER_CUSTOMERS_START",
  FILTER_CUSTOMERS_SUCCESS: "FILTER_CUSTOMERS_SUCCESS",

  GET_REFERRED_USERS_START: "GET_REFERRED_USERS_START",
  GET_REFERRED_USERS_SUCCESS: "GET_REFERRED_USERS_SUCCESS",

  GET_REPORTS_START: "GET_REPORTS_START",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  NO_DATA_REPORTS: "NO_DATA_REPORTS",

  EXPORT_ORDERS_START: "EXPORT_ORDERS_START",
  EXPORT_ORDERS_SUCCESS: "EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAILURE: "EXPORT_ORDERS_FAILURE",

  EXPORT_APPOINTMENT_START: "EXPORT_APPOINTMENT_START",
  EXPORT_APPOINTMENT_SUCCESS: "EXPORT_APPOINTMENT_SUCCESS",
  EXPORT_APPOINTMENT_FAILURE: "EXPORT_APPOINTMENT_FAILURE",

  FILTER_CHAT_STATS_START: "FILTER_CHAT_STATS_START",
  FILTER_CHAT_STATS_SUCCESS: "FILTER_CHAT_STATS_SUCCESS",
  // GET_CHAT_STATS_START: "GET_CHAT_STATS_START"

  GET_CHAT_STATS_START: "GET_CHAT_STATS_START",
  GET_CHAT_STATS_SUCCESS: "GET_CHAT_STATS_SUCCESS",

  FILTER_CHATS_START: "FILTER_CHATS_START",
  FILTER_CHATS_SUCCESS: "FILTER_CHATS_SUCCESS",
};

export default Actions;

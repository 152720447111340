import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  referrals: [],
  referralsData: {},

  isCreating: false,
  createSuccess: false,
  coupon: {}, 

  isEditing: false,
  editSuccess: false,

  isChanging: false,
  changeSuccess: false,

  isDeleting: false,
  deleteSuccess: false,

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_REFERRALS_START:
      return { 
        ...state,
        isGetting: true,
        getSuccess: false,
        referrals: [],
        error: null,
      };
    case Actions.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        changeSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        editSuccess: false,
        referrals: action.payload.rows,
        referralsData: action.payload,
        error: null,
      };
    case Actions.GET_REFERRALS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        referrals: [],
        referralsData: {},
        error: action.payload,
      };
    case Actions.CREATE_REFERRAL_START:
      return {
        ...state,
        isCreating: true,
        createSuccess: false,
        error: null,
      };
    case Actions.CREATE_REFERRAL_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        referral: action.payload,
        error: null,
      };
    case Actions.CREATE_REFERRAL_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSuccess: false,
        error: action.payload,
      };
    case Actions.EDIT_REFERRAL_START:
      return {
        ...state,
        isEditing: true,
        editSuccess: false,
        referral: {},
        error: null,
      };
    case Actions.EDIT_REFERRAL_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editSuccess: true,
        referral: action.payload,
        error: null,
      };
    case Actions.EDIT_REFERRAL_FAILURE:
      return {
        ...state,
        isEditing: false,
        editSuccess: false,
        referral: null,
        error: action.payload,
      };
    case Actions.CHANGE_REFERRAL_STATUS_START:
      return {
        ...state,
        isChanging: true,
        changeSuccess: false,
        referral: {},
        error: null,
      };
    case Actions.CHANGE_REFERRAL_STATUS_SUCCESS:
      return {
        ...state,
        isChanging: false,
        changeSuccess: true,
        referral: action.payload,
        error: null,
      };
    case Actions.CHANGE_REFERRAL_STATUS_FAILURE:
      return {
        ...state,
        isChanging: false,
        changeSuccess: false,
        referral: null,
        error: action.payload,
      };
    case Actions.DELETE_REFERRAL_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        referral: {},
        error: null,
      };
    case Actions.DELETE_REFERRAL_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        referral: action.payload,
        error: null,
      };
    case Actions.DELETE_REFERRAL_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        referral: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;

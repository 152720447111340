import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  auditTrail: [],
  auditTrailData: {},

  isSearching: false,
  searchSuccess: false,
  searchedAuditTrail: [],
  searchedAuditTrailData: {},

  isFiltering: false,
  filterSuccess: false,
  filteredAuditTrail: [],
  filteredAuditTrailData: {},

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_AUDIT_TRAIL_START:
      return {
        ...state,
        isGetting: true,
        error: null,
        getSuccess: false,
      };
    case Actions.GET_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        isGetting: false,
        error: null,
        filterSuccess: false,
        searchSuccess: false,
        auditTrail: action.payload && action.payload.data,
        auditTrailData: action.payload,
        getSuccess: true,
      };
    case Actions.GET_AUDIT_TRAIL_FAILURE:
      return {
        ...state,
        isGetting: false,
        error: action.payload,
        getSuccess: false,
      };
    case Actions.SEARCH_AUDIT_TRAIL_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedAuditTrail: [],
        searchedAuditTrailData: {},
        error: null,
      };
    case Actions.SEARCH_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedAuditTrail: action.payload && action.payload.data,
        searchedAuditTrailData: action.payload,
        error: null,
      };
    case Actions.SEARCH_AUDIT_TRAIL_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedAuditTrail: [],
        searchedAuditTrailData: {},
        error: action.payload,
      };
    case Actions.FILTER_AUDIT_TRAIL_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        filteredAuditTrail: [],
        filteredAuditTrailData: {},
        error: null,
      };
    case Actions.FILTER_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        filteredAuditTrail: action.payload && action.payload.data,
        filteredAuditTrailData: action.payload,
        error: null,
      };
    case Actions.FILTER_AUDIT_TRAIL_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        filteredAuditTrail: [],
        filteredAuditTrailData: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;

import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { cancelModal, viewModal } from "../../components/Modal/Modal";
import Export from "../../components/Overview/Export";
import Figures from "../../components/Overview/Figures";
import Graph from "../../components/Overview/Graph";
import ProductDistributionGraph from "../../components/Overview/ProductDistributionGraph";
// import AppointmentDistribution from "../../components/Overview/AppointmentDistribution";
import AppointmentDistributionLine from "../../components/Overview/AppointmentDistributionLine";
import ChatDistributionLine from "../../components/Overview/ChatDistributionLine";
import RecentAppointments from "../../components/Overview/RecentAppointments";
import RecentOrders from "../../components/Overview/RecentOrders";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getOverview } from "../../redux/Actions/OverviewActions";
import OverviewLoader from "./OverviewLoader";
import html2pdf from "html2pdf.js";
import downloadCSV from "../../utils/functions/csvDownloader";
// import AppointmentsPerHourGraph from "../../components/Overview/AppointmentsPerHourGraph";
import GenderDistributionChart from "../../components/Overview/GenderDistributionChart";
import AgeGroupChart from "../../components/Overview/AgeGroupChart";
import DistributionLines from "../../components/Overview/DistributionLines";
import PrescriptionIssuedGraph from "../../components/Overview/PrescriptionIssuedGraph";
const Dashboard = (props) => {
  const { isGetting, getSuccess, overviewData, fetchOverview } = props;

  const [state, setState] = useState({
    season: "year",
    rescheduleModal: false,
    declineModal: false,
    deleteModal: false,
    scheduleModal: false,
    date: "",
  });

  const wrapperRef = useRef();
  const [figuresData, setFiguresData] = useState(null);
  const [currentGraphType, setCurrentGraphType] = useState("year");

  const exportHandler = (format) => {
    switch (format) {
      case "PDF":
        var opt = {
          filename: `overview-${Date.now()}.pdf`,
          margin: 10,
          html2canvas: { scale: 2 },
          jsPDF: { format: "letter", orientation: "landscape" },
        };
        html2pdf().set(opt).from(wrapperRef.current).save();
        return;
      case "CSV":
        const order = overviewData.order.map((order) => ({
          Placed: new Date(order.created_at).toLocaleDateString(),
          "Order Number": order.order_number,
          Price: `NGN${order.total_item_price}`,
          Status: order.status,
        }));
        downloadCSV(order, "recent-orders");
        return;
      default:
        return;
    }
  };

  const filterOverviewHandler = (queries, type) => {
    fetchOverview({ ...queries, type });
    setCurrentGraphType(type);
  };

  useEffect(
    () => {
      fetchOverview({ year: new Date().getFullYear(), type: "year" });
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (!isGetting && getSuccess) {
        setFiguresData({
          total_user: overviewData.total_user,
          total_appointment: overviewData.total_appointment,
          total_product: overviewData.total_product ?? 0,
          total_chat: overviewData.total_chat ?? 0,
        });
      }
    }, // eslint-disable-next-line
    [isGetting]
  );

  const [showDate, setShowDate] = useState(false);

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const data = {
    mostSoldProducts: overviewData?.most_sold_product,
    totalNoOfProducts: overviewData?.total_product,
  };

  const graphData = {
    revenue_graph: overviewData.revenue_graph,
    sales_graph: overviewData.sales_graph,
    product_distribution: overviewData.product_distribution,
    chat_distribution: overviewData.chat_distribution,
    appointments_scheduled_per_hour:
      overviewData.appointments_scheduled_per_hour,
  };

  return (
    <div className="overview" ref={wrapperRef}>
      <HeaderWrapper
        title={"Overview"}
        subtitle={"Here is what is happening on your overview page today"}
        className={"overview-header-right"}
      >
        {/* <Export
          exportHandler={exportHandler}
          showDate={showDate}
          setShowDate={setShowDate}
          refetchOverview={filterOverviewHandler}
        /> */}
      </HeaderWrapper>
      {isGetting ? (
        <OverviewLoader />
      ) : !isGetting && getSuccess ? (
        <>
          {figuresData && <Figures figuresData={figuresData} />}
          <Graph graphData={graphData} graphType={currentGraphType} />
          <ProductDistributionGraph
            graphData={graphData}
            graphType={currentGraphType}
          />

          {/* Appointment distribution - Old  */}
          {/* <AppointmentDistribution
						data={overviewData.appointment_distribution}
					/> */}

          {/* Appointment distribution - newly added  */}
          <AppointmentDistributionLine
            overviewData={overviewData}
            graphType={currentGraphType}
          />
          {/* <AppointmentsPerHourGraph
            overviewData={overviewData}
            graphType={currentGraphType}
          /> */}
          <div className="chart-grid">
            <div className="chart-grid-left">
              <AgeGroupChart overviewData={overviewData} />
            </div>
            <div className="chart-grid-right">
              <GenderDistributionChart overviewData={overviewData} />
            </div>
          </div>
          <DistributionLines
            graph_name={"Residential Demographic"}
            data={overviewData?.residential_demographic}
            label={"state"}
          />
          <DistributionLines
            graph_name={"Delivery Area Distribution"}
            data={overviewData?.delivery_area_distribution}
            label={"place"}
          />
          {/* Chat distribution - newly added  */}
          <ChatDistributionLine
            overviewData={overviewData}
            graphType={currentGraphType}
          />
          <PrescriptionIssuedGraph
            overviewData={overviewData}
            graphType={currentGraphType}
          />

          <RecentAppointments
            openModal={openModal}
            closeModal={closeModal}
            c
            state={state}
            setState={setState}
            products={data}
            appointments={overviewData?.appointment}
          />
          <RecentOrders recentOrders={overviewData.order} />
        </>
      ) : (
        <div>Error fetching overview details</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGetting: state.overview.isGetting,
  overviewData: state.overview.overviewData,
  getSuccess: state.overview.getSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOverview: (payload) => dispatch(getOverview(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import Actions from "../Types";

const initialState = {
  isUpdatingProfile: false,
  isChangingPassword: false,
  changePasswordSuccess: false,
  updateProfileSuccess: false,
  data: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CHANGE_PASSWORD_START:
      return {
        ...state,
        isChangingPassword: true,
        data: {},
        changePasswordSuccess: false,
        error: null,
      };
    case Actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false,
        changePasswordSuccess: true,
        data: action.payload,
        error: null,
      };
    case Actions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        changePasswordSuccess: false,
        data: null,
        error: action.payload,
      };
    case Actions.UPDATE_PROFILE_START:
      return {
        ...state,
        isUpdatingProfile: true,
        updateProfileSuccess: false,
        data: {},
        error: null,
      };
    case Actions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdatingProfile: false,
        updateProfileSuccess: true,
        data: action.payload,
        error: null,
      };
    case Actions.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        isUpdatingProfile: false,
        updateProfileSuccess: false,
        data: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;

import Actions from "../Types";

const initialState = {
  isGetting: false,
  getSuccess: false,
  users: [],
  usersData: {},

  isGettingProfile: false,
  getSuccessProfile: false,
  usersProfileData: {},

  isSearching: false,
  searchSuccess: false,
  searchedUsers: [],
  searchedUsersData: {},

  isFiltering: false,
  filterSuccess: false,
  filteredUsers: [],
  filteredUsersData: {},

  isCreating: false,
  createSuccess: false,
  user: {},

  isDeleting: false,
  deleteSuccess: false,

  isRevoking: false,
  revokeSuccess: false,

  isGettingUserChat: false,
  getSuccessUserChat: false,
  userChat: [],

  isSearchingUserChat: false,
  searchSuccessUserChat: false,
  searchedUserChat: [],

  isTransfering: false,
  transferSuccess: false,
  chat: [],

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_USERS_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        users: [],
        error: null,
      };
    case Actions.GET_USERS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        revokeSuccess: false,
        createSuccess: false,
        deleteSuccess: false,
        searchSuccess: false,
        filterSuccess: false,
        users: action.payload && action.payload.rows,
        usersData: action.payload,
        error: null,
      };
    case Actions.GET_USERS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        users: [],
        usersData: {},
        error: action.payload,
      };
    case Actions.GET_USERS_PROFILE_START:
      return {
        ...state,
        isGettingProfile: true,
        getSuccessProfile: false,
        error: null,
      };
    case Actions.GET_USERS_PROFILE_SUCCESS:
      return {
        ...state,
        isGettingProfile: false,
        getSuccessProfile: true,
        usersProfileData: action.payload,
        error: null,
      };
    case Actions.GET_USERS_PROFILE_FAILURE:
      return {
        ...state,
        isGettingProfile: false,
        getSuccessProfile: false,
        usersProfileData: {},
        error: action.payload,
      };
    case Actions.SEARCH_USERS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedUsers: [],
        searchedUsersData: {},
        error: null,
      };
    case Actions.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedUsers: action.payload && action.payload.rows,
        searchedUsersData: action.payload,
        error: null,
      };
    case Actions.SEARCH_USERS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedUsers: [],
        searchedUsersData: {},
        error: action.payload,
      };
    case Actions.FILTER_USERS_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        filteredUsers: [],
        filteredUsersData: {},
        error: null,
      };
    case Actions.FILTER_USERS_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        filteredUsers: action.payload && action.payload.rows,
        filteredUsersData: action.payload,
        error: null,
      };
    case Actions.FILTER_USERS_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        filteredUsers: [],
        filteredUsersData: {},
        error: action.payload,
      };
    case Actions.CREATE_USER_START:
      return {
        ...state,
        isCreating: true,
        createSuccess: false,
        error: null,
      };
    case Actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createSuccess: true,
        user: action.payload,
        error: null,
      };
    case Actions.CREATE_USER_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSuccess: false,
        error: action.payload,
      };
    case Actions.DELETE_USER_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        user: {},
        error: null,
      };
    case Actions.DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        user: action.payload,
        error: null,
      };
    case Actions.DELETE_USER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        user: null,
        error: action.payload,
      };
    case Actions.REVOKE_USER_START:
      return {
        ...state,
        isRevoking: true,
        revokeSuccess: false,
        user: null,
        error: null,
      };
    case Actions.REVOKE_USER_SUCCESS:
      return {
        ...state,
        isRevoking: false,
        revokeSuccess: true,
        user: action.payload,
        error: null,
      };
    case Actions.REVOKE_USER_FAILURE:
      return {
        ...state,
        isRevoking: false,
        revokeSuccess: false,
        user: null,
        error: action.payload,
      };
    case Actions.GET_USER_CHAT_HISTORY_START:
      return {
        ...state,
        isGettingUserChat: true,
        getSuccessUserChat: false,
        transferSuccess: false,
        userChat: [],
        error: null,
      };
    case Actions.GET_USER_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        isGettingUserChat: false,
        getSuccessUserChat: true,
        searchSuccessUserChat: false,
        transferSuccess: false,
        userChat: action.payload,
        error: null,
      };
    case Actions.GET_USER_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        isGettingUserChat: false,
        getSuccessUserChat: false,
        transferSuccess: false,
        userChat: [],
        error: action.payload,
      };
    case Actions.SEARCH_USER_CHAT_HISTORY_START:
      return {
        ...state,
        isSearchingUserChat: true,
        searchSuccessUserChat: false,
        searchedUserChat: [],
        error: null,
      };
    case Actions.SEARCH_USER_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        isSearchingUserChat: false,
        searchSuccessUserChat: true,
        searchedUserChat: action.payload,
        error: null,
      };
    case Actions.SEARCH_USER_CHAT_HISTORY_FAILURE:
      return {
        ...state,
        isSearchingUserChat: false,
        searchSuccessUserChat: false,
        searchedUserChat: [],
        error: action.payload,
      };
    case Actions.TRANSFER_USER_CHAT_START:
      return {
        ...state,
        isTransfering: true,
        transferSuccess: false,
        chat: [],
        error: null,
      };
    case Actions.TRANSFER_USER_CHAT_SUCCESS:
      return {
        ...state,
        isTransfering: false,
        transferSuccess: true,
        chat: action.payload,
        error: null,
      };
    case Actions.TRANSFER_USER_CHAT_FAILURE:
      return {
        ...state,
        isTransfering: false,
        transferSuccess: false,
        chat: [],
        error: action.payload,
      };
    case Actions.CLEAR_USERS_STATE:
      return {
        deleteSuccess: false,
      };
    default:
      return state;
  }
};

export default reduxState;

import Actions from "../Types";

const initialState = {
  isGetting: false,
  isSearching: false,
  isFiltering: false,
  getSuccess: false,
  searchSuccess: false,
  filterSuccess: false,
  isCreating: false,
  isEditing: false,
  isDeleting: false,
  isChangingStatus: false,
  isStocking: false,
  allProducts: [],
  allProductsData: {},
  singleProduct: {},
  filteredProducts: [],
  filteredProductsData: {},
  searchedProducts: [],
  searchedProductsData: {},
  product: {},
  isGettingHistory: false,
  productHistory: [],
  productHistoryData: {},
  success: false,
  getHistorySuccess: false,
  editSuccess: false,
  stockSuccess: false,
  deleteSuccess: false,
  changeStatusSuccess: false,
  error: null,
  isPushingToTop: false,
  pushToTopSuccess: false
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ALL_PRODUCTS_START:
      return {
        ...state,
        isGetting: true,
        allProducts: [],
        allProductsData: {},
        getSuccess: false,
        searchSuccess: false,
        filterSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        changeStatusSuccess: false,
        deleteSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        allProducts: action.payload && action.payload.products,
        allProductsData: action.payload,
        searchSuccess: false,
        filterSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        changeStatusSuccess: false,
        getSuccess: true,
        deleteSuccess: false,
        pushToTopSuccess: false,
        error: null,
      };
    case Actions.GET_ALL_PRODUCTS_FAILURE:
      return {
        ...state,
        isGetting: false,
        allProducts: [],
        allProductsData: {},
        searchSuccess: false,
        filterSuccess: false,
        getSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        changeStatusSuccess: false,
        deleteSuccess: false,
        error: action.payload,
      };
    case Actions.GET_SINGLE_PRODUCT_START:
      return {
        ...state,
        isGetting: true,
        singleProduct: {},
        success: false,
        error: null,
      };
    case Actions.GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        isGetting: false,
        singleProduct: action.payload,
        success: true,
        error: null,
      };
    case Actions.GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        isGetting: false,
        singleProduct: {},
        success: false,
        error: action.payload,
      };
    case Actions.CREATE_PRODUCT_START:
      return {
        ...state,
        isCreating: true,
        product: {},
        error: null,
        success: false,
      };
    case Actions.CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        product: action.payload,
        error: null,
        success: true,
      };
    case Actions.CREATE_PRODUCT_FAILURE:
      return {
        ...state,
        isCreating: false,
        product: {},
        error: action.payload,
        success: false,
      };
    case Actions.EDIT_PRODUCT_START:
      return {
        ...state,
        isEditing: true,
        product: {},
        editSuccess: false,
        error: null,
      };
    case Actions.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        isEditing: false,
        product: action.payload,
        editSuccess: true,
        error: null,
      };
    case Actions.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        isEditing: false,
        product: null,
        editSuccess: false,
        error: action.payload,
      };
    case Actions.CHANGE_PRODUCT_STATUS_START:
      return {
        ...state,
        isChangingStatus: true,
        product: null,
        changeStatusSuccess: false,
        deleteSuccess: false,
        error: null,
      };
    case Actions.CHANGE_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        isChangingStatus: false,
        product: action.payload,
        changeStatusSuccess: true,
        deleteSuccess: false,
        error: null,
      };
    case Actions.CHANGE_PRODUCT_STATUS_FAILURE:
      return {
        ...state,
        isChangingStatus: false,
        product: null,
        changeStatusSuccess: false,
        deleteSuccess: false,
        error: action.payload,
      };
    case Actions.STOCK_UP_PRODUCT_START:
      return {
        ...state,
        isStocking: true,
        stockSuccess: false,
        product: null,
        error: null,
      };
    case Actions.STOCK_UP_PRODUCT_SUCCESS:
      return {
        ...state,
        isStocking: false,
        product: action.payload,
        stockSuccess: true,
        error: null,
      };
    case Actions.STOCK_UP_PRODUCT_FAILURE:
      return {
        ...state,
        isStocking: false,
        product: null,
        stockSuccess: false,
        error: action.payload,
      };
    case Actions.DELETE_PRODUCT_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        changeStatusSuccess: false,
        product: null,
        error: null,
      };
    case Actions.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        product: action.payload,
        deleteSuccess: true,
        changeStatusSuccess: false,
        error: null,
      };
    case Actions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isDeleting: false,
        product: null,
        deleteSuccess: false,
        error: action.payload,
      };
    case Actions.FILTER_PRODUCTS_START:
      return {
        ...state,
        isFiltering: true,
        filteredProducts: [],
        filteredProductsData: {},
        filterSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.FILTER_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filteredProducts: action.payload && action.payload.products,
        filteredProductsData: action.payload,
        filterSuccess: true,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.FILTER_PRODUCTS_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filteredProducts: [],
        filteredProductsData: {},
        filterSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        searchSuccess: false,
        error: action.payload,
      };
    case Actions.SEARCH_PRODUCTS_START:
      return {
        ...state,
        isSearching: true,
        searchedProducts: [],
        searchedProductsData: {},
        searchSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filterSuccess: false,
        error: null,
      };
    case Actions.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedProducts: action.payload && action.payload.products,
        searchedProductsData: action.payload,
        searchSuccess: true,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filterSuccess: false,
        error: null,
      };
    case Actions.SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchedProducts: [],
        searchedProductsData: {},
        searchSuccess: false,
        editSuccess: false,
        stockSuccess: false,
        deleteSuccess: false,
        changeStatusSuccess: false,
        filterSuccess: false,
        error: action.payload,
      };
    case Actions.GET_PRODUCT_HISTORY_START:
      return {
        ...state,
        isGettingHistory: true,
        productHistory: [],
        productHistoryData: {},
        getHistorySuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.GET_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        isGettingHistory: false,
        productHistory: action.payload && action.payload.products,
        productHistoryData: action.payload,
        filterSuccess: false,
        searchSuccess: false,
        getHistorySuccess: true,
        error: null,
      };
    case Actions.GET_PRODUCT_HISTORY_FAILURE:
      return {
        ...state,
        isGettingHistory: false,
        productHistory: [],
        productHistoryData: {},
        getHistorySuccess: false,
        filterSuccess: false,
        searchSuccess: false,
        error: action.payload,
      };
    case Actions.FILTER_PRODUCT_HISTORY_START:
      return {
        ...state,
        isFiltering: true,
        filteredProducts: [],
        filteredProductsData: {},
        filterSuccess: false,
        getHistorySuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.FILTER_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filteredProducts: action.payload && action.payload.products,
        filteredProductsData: action.payload,
        filterSuccess: true,
        getHistorySuccess: false,
        searchSuccess: false,
        error: null,
      };
    case Actions.FILTER_PRODUCT_HISTORY_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filteredProducts: [],
        filteredProductsData: {},
        filterSuccess: false,
        getHistorySuccess: false,
        searchSuccess: false,
        error: action.payload,
      };
    case Actions.SEARCH_PRODUCT_HISTORY_START:
      return {
        ...state,
        isSearching: true,
        searchedProducts: [],
        searchedProductsData: {},
        searchSuccess: false,
        getHistorySuccess: false,
        filterSuccess: false,
        error: null,
      };
    case Actions.SEARCH_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedProducts: action.payload && action.payload.products,
        searchedProductsData: action.payload,
        searchSuccess: true,
        getHistorySuccess: false,
        filterSuccess: false,
        error: null,
      };
    case Actions.SEARCH_PRODUCT_HISTORY_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchedProducts: [],
        searchedProductsData: {},
        searchSuccess: false,
        getHistorySuccess: false,
        filterSuccess: false,
        error: action.payload,
      };
      case Actions.PUSH_PRODUCT_TO_TOP_START:
        return {
          ...state,
          isPushingToTop: true,
          pushToTopSuccess: false
        }
      case Actions.PUSH_APPOINTMEMT_TO_TOP_SUCCESS:
        return {
          ...state,
          isPushingToTop: false,
          pushToTopSuccess: true
        }
    default:
      return state;
  }
};

export default reduxState;

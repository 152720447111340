import React, { useEffect, useState } from "react";
import DateFilter from "../../components/Input/DateFilter";
import Modal, { ModalButton } from "../../components/Modal/Modal";
import MultiDropdown from "../../components/Input/MultiDropdown";

const FilterOrders = (props) => {
  const {
    displayModal,
    closeModal,
    isFiltering,
    filterOrders,
    filterSuccess,
    prevstate,
    setPrevState,
  } = props;
  const [state, setState] = useState({
    startDate: "",
    endDate: "",
    active: false,
    status: "",
    index: "",
    statuses: [],
  });


  const status = [
    { category_id: "pending", name: "Pending" },
    { category_id: "processing", name: "Processing" },
    { category_id: "delivered", name: "Delivered" },
    { category_id: "cancel", name: "Cancel" },
  ];

  const handleFilter = () => {
    const start_date = state.startDate;
    const end_date = state.endDate;
    const statusMap = state.statuses.map(el => el.category_id)
    const status = statusMap.join(",")
    const payload = {
      start_date: state.startDate,
      end_date: state.endDate,
      status: status
    };
    setPrevState({ ...prevstate, filterValues: payload });
    filterOrders(1, 10, start_date, end_date, status);
  };

  useEffect(
    () => {
      if (filterSuccess) {
        closeModal();
        setState({
          ...state,
          startDate: "",
          endDate: "",
          active: false,
          status: "",
          index: "",
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  const disabled =
    isFiltering;

  return (
    <div>
      <Modal
        displayModal={displayModal}
        closeModal={closeModal}
        title={"Filter"}
        width={"496px"}
      >
        {/* <div className="form-grid3">
          <DateFilter
            label={"Start Date"}
            placeholder={"Select date"}
            state={state}
            name={"startDate"}
            setState={setState}
            success={filterSuccess}
          />
          <DateFilter
            label={"End Date"}
            placeholder={"Select date"}
            state={state}
            name={"endDate"}
            setState={setState}
            success={filterSuccess}
          />
        </div> */}

        {/* {prevstate.active1 && (
          <div className="top-spacer">
            <Statuses
              label={"Status"}
              state={state}
              options={status}
              setstate={setState}
              numberPerRow={"3"}
              blue={false}
            />
          </div>
        )} */}
        <MultiDropdown
          state={state}
          accessor="statuses"
          label="Status"
          placeholder={
            state.statuses.length > 0
              ? "Filter by more statuses..."
              : "e.g Select Statuses"
          }
          top={"30px"}
          testid={"statuses-tags"}
          options={status}
          onSelect={(statuses) => setState((state) => ({ ...state, statuses }))}
          onRemove={(statuses) => setState((state) => ({ ...state, statuses }))}
        />
        <ModalButton
          isLoading={isFiltering}
          text={"Apply Filter"}
          testId={"button"}
          loadingText={"Filtering..."}
          disabled={disabled}
          onClick={handleFilter}
        />
      </Modal>
    </div>
  );
};

export default FilterOrders;

import React, { useEffect, useState } from "react";
import SortData from "../../components/Sorting/SortData";
import { cancelModal } from "../../components/Modal/Modal";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import FilterOrders from "./FilterOrders";
import OrdersColumns from "../../components/TableData/Orders/Orders";
import { useHistory } from "react-router-dom";
import ExportIcon from "../../assets/Union.svg";
import DropIcon from "../../assets/drop-export.svg";
import moment from "moment";
import {
  exportOrdersData,
  filterOrders,
  getAllOrders,
  getOrdersByStatus,
  searchOrders,
} from "../../redux/Actions/OrderActions";
import Add from "../../assets/add.svg";
import { connect } from "react-redux";
import PendingColumns from "../../components/TableData/Orders/Pending";
import ProcessingColumns from "../../components/TableData/Orders/Processing";
import DeliveredColumns from "../../components/TableData/Orders/Delivered";
import CanceledColumns from "../../components/TableData/Orders/Canceled";
import UnsuccessfulColumns from "../../components/TableData/Orders/Unsuccessful";
import downloadCSV from "../../utils/functions/csvDownloader";
import { PageButton } from "../../components/Button/Button";
import CreateOrder from "../../components/Orders/CreateOrder";
import TableDropdown from "../../components/Tables/Dropdown";
import { LoaderSpin } from "../../components/Loader/Loader";
import Alert from "../../utils/notifications/Alert";
import CustomDropdown from "../../components/CustomDropdown";
import OptionIcon from "../../assets/option_icon.svg";
import { DateRangePicker } from "react-date-range";
import { useOverviewTypeContext } from "../../contexts/OverviewTypeContext";

const Orders = (props) => {
  const {
    isGettingOrders,
    getOrdersSuccess,
    orders,
    ordersData,
    getAllOrders,
    allOrdersByStatus,
    getOrdersByStatus,
    searchOrders,
    filterOrders,
    isSearchingOrders,
    searchSuccess,
    searchedOrders,
    searchedOrdersData,
    isFilteringOrders,
    filterSuccess,
    filteredOrders,
    filteredOrdersData,
    allOrdersByStatusData,
    changeSuccess,
    isLoadingExportData,
    exportDataSuccess,
    exportOrdersDataHandler,
  } = props;
  const [show, setShow] = useState(false);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [seasonsTabs, setSeasonsTabs] = useState(["yearly", "monthly"]);
  const [activeDropdown, setActiveDropdown] = useState("yearly");
  const overviewType = useOverviewTypeContext();
  const setOverviewType = overviewType?.setOverviewType;
  const [rangeSelected, setRangeSelected] = useState(false);
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [state, setState] = useState({
    active1: true,
    active2: false,
    active3: false,
    active4: false,
    active5: false,
    active6: false,
    addModal: false,
    filterModal: false,
    searchValue: "",
    orderDetails: false,
    id: "",
    order_id: "",
    filterValues: {},
  });

  const checkType = state.active2
    ? "pending"
    : state.active3
    ? "processing"
    : state.active4
    ? "delivered"
    : state.active5
    ? "canceled"
    : state.active6
    ? "failed"
    : "";

  const history = useHistory();

  const [selectedRows, setSelectedRows] = useState([]);

  const openModal = (type) => {
    setState({
      ...state,
      show: false,
      [type]: true,
    });
  };
  const closeModal = (type) => cancelModal(type, state, setState);

  const goToSinglePage = (id) => {
    history.push({
      pathname: `/order-management/${id}`,
      state: {
        id: id,
        data: state.data,
      },
    });
  };

  const options = [
    {
      name: "View",
      action: () => goToSinglePage(state.order_id),
    },
  ];

  const searchAllOrders = () => {
    if (state.searchValue === "") {
      return orders;
    } else {
      searchOrders(1, 10, checkType, state.searchValue);
    }
  };

  const type = state.active2
    ? "pending"
    : state.active3
    ? "processing"
    : state.active4
    ? "delivered"
    : state.active5
    ? "canceled"
    : state.active6
    ? "pending"
    : "";

  const moreData = (page, perPage) => {
    const start_date = state.filterValues?.start_date;
    const end_date = state.filterValues?.end_date;
    const status = state.filterValues?.status;
    searchSuccess
      ? searchOrders(page, perPage, type, state.searchValue)
      : filterSuccess
      ? filterOrders(page, perPage, start_date, end_date, status)
      : getAllOrders(page, perPage);
  };

  const allOrders = searchSuccess
    ? searchedOrders
    : filterSuccess
    ? filteredOrders
    : orders;

  const totalOrders = searchSuccess
    ? searchedOrdersData?.total
    : filterSuccess
    ? filteredOrdersData?.total
    : ordersData?.total;

  const totalOrdersStatus = searchSuccess
    ? searchedOrdersData?.total
    : filterSuccess
    ? filteredOrdersData?.total
    : allOrdersByStatusData?.total;

  const refetchOverview = (queries, type) => {
    setState({
      ...state,
      filterValues: {
        start_date: queries.start_date,
        end_date: queries.end_date,
      },
    });
    filterOrders(1, 10, queries.start_date, queries.end_date);
  };

  const padTo2Digits = (num) => num.toString().padStart(2, "0");

  const customRangeToggler = (
    <div className={`mini__tab${activeDropdown === "range" ? " active" : ""}`}>
      <div>
        <img src={OptionIcon} alt="option-icon" />
      </div>
    </div>
  );

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  const rangeFilterHandler = () => {
    const { startDate, endDate } = selectionRange[0];
    const start_date = formatDate(startDate);
    const end_date = formatDate(endDate);
    setActiveDropdown("range");
    refetchOverview({ start_date, end_date }, "week");
    setOverviewType({ start_date, end_date, type: "week" });
    setRangeSelected((range) => !range);
    setSelectedYear(new Date().getFullYear());
    setSeasonsTabs(["yearly", "monthly"]);
  };

  const allOrdersStatus = searchSuccess
    ? searchedOrders
    : filterSuccess
    ? filteredOrders
    : allOrdersByStatus;

  const moreDataStatus = (page, perPage, type, payment_status) => {
    const start_date = state.filterValues?.start_date;
    const end_date = state.filterValues?.end_date;
    const status = state.filterValues?.status;

    searchSuccess
      ? searchOrders(page, perPage, type, state.searchValue)
      : filterSuccess
      ? filterOrders(page, perPage, start_date, end_date, status)
      : getOrdersByStatus(page, perPage, type, payment_status);
  };
  const exportHandler = (format) => {
    switch (format) {
      case "CSV":
        // if (!state.filterValues.start_date && !state.filterValues._date) {
        //   return Alert(
        //     "error",
        //     "Failed",
        //     "",
        //     "Please Select Filters before Generating CSV"
        //   );
        // }
        exportOrdersDataHandler(
          state.filterValues?.start_date,
          state.filterValues?.end_date,
          state.filterValues?.status,
          ""
        );
        return;
      default:
        return;
    }
  };

  const export_options = [
    {
      name: isLoadingExportData ? <LoaderSpin /> : "CSV",
      action: () => exportHandler("CSV"),
    },
  ];

  useEffect(
    () => {
      if (getOrdersSuccess) {
        setState({
          ...state,
          searchValue: "",
        });
      }
    },
    // eslint-disable-next-line
    [getOrdersSuccess]
  );
  return (
    <div
      className="orders"
      style={{
        marginTop: "5rem",
      }}
    >
      <HeaderWrapper
        title={"User Orders"}
        subtitle={"Helps you view and manage all your Orders"}
        // children={

        // }
      >
        <div className="overview-header-right">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "14rem",
            }}
            className="overview-header-right-button"
          >
            <PageButton
              width={"auto"}
              background={"rgba(10, 116, 220, 0.08)"}
              marginRight={"10px"}
              onClick={() => openModal("addModal")}
              type={"button"}
            >
              <div className="button-data">
                <img src={Add} alt="add-icon" />
                <span>Create Order</span>
              </div>
            </PageButton>
            <PageButton
              width={"121px"}
              mainWidth={"144px"}
              background={"rgba(10, 116, 220, 0.08)"}
              // marginTop={"35px"}
              type={"button"}
              onClick={() => setShow((prevCheck) => !prevCheck)}
            >
              <div className="button-data">
                <img src={ExportIcon} style={{
                  marginRight: "0.8rem"
                }} alt="export icon" />
                Export
                <img src={DropIcon} style={{
                  marginLeft: "0.8rem"
                }} alt="dropdown" />
              </div>
            </PageButton>
            {show && (
              <TableDropdown
                options={export_options}
                hideDropdown={() => setShow((prevCheck) => !prevCheck)}
                active
              />
            )}
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "3.2rem",
                }}
              >
                <h1
                  className=""
                  style={{
                    fontSize: "1.5rem",
                    marginTop: "1rem",
                    marginRight: "1rem",
                  }}
                >
                  DATE RANGE:
                </h1>
                <CustomDropdown
                  tab={customRangeToggler}
                  optionSelected={rangeSelected}
                >
                  <DateRangePicker
                    className="date-range-picker"
                    ranges={selectionRange}
                    onChange={({ selection }) => setSelectionRange([selection])}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      onClick={() => rangeFilterHandler()}
                      className="date-range-picker-btn"
                    >
                      SELECT RANGE
                    </button>
                  </div>
                </CustomDropdown>
              </div>
            </div>
          </div>
        </div>
      </HeaderWrapper>
      <SwitchWrapper
        title1={"All Orders"}
        // title2={"Pending"}
        // title3={"Processing"}
        // title4={"Delivered"}
        // title5={"Canceled"}
        state={state}
        setstate={setState}
        component1={
          <OrdersColumns
            options={options}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            state={state}
            setState={setState}
            isLoading={
              isGettingOrders || isSearchingOrders || isFilteringOrders
            }
            success={getOrdersSuccess || searchSuccess || filterSuccess}
            sortSucces={searchSuccess || filterSuccess}
            data={allOrders}
            total={totalOrders}
            getData={(page, limit) => getAllOrders(page, limit)}
            getMoreData={(page, perPage) => moreData(page, perPage)}
          />
        }
        // component2={
        //   <PendingColumns
        //     options={options}
        //     selectedRows={selectedRows}
        //     setSelectedRows={setSelectedRows}
        //     state={state}
        //     setState={setState}
        //     isLoading={
        //       isGettingOrders || isSearchingOrders || isFilteringOrders
        //     }
        //     success={getOrdersSuccess || searchSuccess || filterSuccess}
        //     sortSucces={searchSuccess || filterSuccess}
        //     data={allOrdersStatus}
        //     getMoreData={(page, perPage) =>
        //       moreDataStatus(page, perPage, "pending", "")
        //     }
        //     total={totalOrdersStatus}
        //     getData={(page, limit) =>
        //       getOrdersByStatus(page, limit, "pending", "")
        //     }
        //     changeSuccess={changeSuccess}
        //   />
        // }
        // component3={
        //   <ProcessingColumns
        //     options={options}
        //     selectedRows={selectedRows}
        //     setSelectedRows={setSelectedRows}
        //     state={state}
        //     setState={setState}
        //     isLoading={
        //       isGettingOrders || isSearchingOrders || isFilteringOrders
        //     }
        //     success={getOrdersSuccess || searchSuccess || filterSuccess}
        //     sortSucces={searchSuccess || filterSuccess}
        //     data={allOrdersStatus}
        //     getMoreData={(page, perPage) =>
        //       moreDataStatus(page, perPage, "processing", "")
        //     }
        //     total={totalOrdersStatus}
        //     getData={(page, limit) =>
        //       getOrdersByStatus(page, limit, "processing", "")
        //     }
        //     changeSuccess={changeSuccess}
        //   />
        // }
        // component4={
        //   <DeliveredColumns
        //     options={options}
        //     selectedRows={selectedRows}
        //     setSelectedRows={setSelectedRows}
        //     state={state}
        //     setState={setState}
        //     isLoading={
        //       isGettingOrders || isSearchingOrders || isFilteringOrders
        //     }
        //     success={getOrdersSuccess || searchSuccess || filterSuccess}
        //     sortSucces={searchSuccess || filterSuccess}
        //     data={allOrdersStatus}
        //     getMoreData={(page, perPage) =>
        //       moreDataStatus(page, perPage, "delivered", "")
        //     }
        //     getData={(page, limit) =>
        //       getOrdersByStatus(page, limit, "delivered", "")
        //     }
        //     total={totalOrdersStatus}
        //     changeSuccess={changeSuccess}
        //   />
        // }
        // component5={
        //   <CanceledColumns
        //     options={options}
        //     selectedRows={selectedRows}
        //     setSelectedRows={setSelectedRows}
        //     state={state}
        //     setState={setState}
        //     isLoading={
        //       isGettingOrders || isSearchingOrders || isFilteringOrders
        //     }
        //     success={getOrdersSuccess || searchSuccess || filterSuccess}
        //     sortSucces={searchSuccess || filterSuccess}
        //     data={allOrdersStatus}
        //     getMoreData={(page, perPage) =>
        //       moreDataStatus(page, perPage, "canceled", "")
        //     }
        //     getData={(page, limit) =>
        //       getOrdersByStatus(page, limit, "canceled", "")
        //     }
        //     total={totalOrdersStatus}
        //     changeSuccess={changeSuccess}
        //   />
        // }
        // component6={
        //   <UnsuccessfulColumns
        //     options={options}
        //     selectedRows={selectedRows}
        //     setSelectedRows={setSelectedRows}
        //     state={state}
        //     setState={setState}
        //     isLoading={
        //       isGettingOrders || isSearchingOrders || isFilteringOrders
        //     }
        //     success={getOrdersSuccess || searchSuccess || filterSuccess}
        //     sortSucces={searchSuccess || filterSuccess}
        //     data={allOrdersStatus}
        //     getMoreData={(page, perPage) =>
        //       moreDataStatus(page, perPage, "pending", "failed,pending")
        //     }
        //     getData={(page, limit) =>
        //       getOrdersByStatus(page, limit, "pending", "failed,pending")
        //     }
        //     total={totalOrdersStatus}
        //     changeSuccess={changeSuccess}
        //   />
        // }
        right1={
          <SortData
            placeholder={"Search by order no, customer name"}
            width={"247px"}
            searchItems={searchAllOrders}
            setState={setState}
            state={state}
            onClick={() => openModal("filterModal")}
            filter={true}
            sortSuccess={filterSuccess || searchSuccess}
            refreshFunction={
              state.active1
                ? () => getAllOrders(1, 10)
                : () => getOrdersByStatus(1, 10, type)
            }
          />
        }
        right2={"false"}
      />
      <CreateOrder
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
      />
      <FilterOrders
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        isFiltering={isFilteringOrders}
        filterOrders={filterOrders}
        filterSuccess={filterSuccess}
        type={type}
        prevstate={state}
        setPrevState={setState}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  isGettingOrders: state.order.isGetting,
  getOrdersSuccess: state.order.getSuccess,
  orders: state.order.allOrders,
  ordersData: state.order.allOrdersData,
  allOrdersByStatus: state.order.allOrdersByStatus,
  allOrdersByStatusData: state.order.allOrdersByStatusData,
  isSearchingOrders: state.order.isSearchingOrders,
  searchSuccess: state.order.searchSuccess,
  searchedOrders: state.order.searchedOrders,
  searchedOrdersData: state.order.searchedOrdersData,
  isFilteringOrders: state.order.isFilteringOrders,
  filterSuccess: state.order.filterSuccess,
  filteredOrders: state.order.filteredOrders,
  filteredOrdersData: state.order.filteredOrdersData,
  changeSuccess: state.order.changeSuccess,
  isLoadingExportData: state.order.isLoadingExportData,
  exportDataSuccess: state.order.exportDataSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getAllOrders: (page, limit) => dispatch(getAllOrders(page, limit)),
  searchOrders: (page, limit, type, searchValue) =>
    dispatch(searchOrders(page, limit, type, searchValue)),
  filterOrders: (page, limit, start_date, end_date, status) =>
    dispatch(filterOrders(page, limit, start_date, end_date, status)),
  getOrdersByStatus: (page, limit, status, payment_status) =>
    dispatch(getOrdersByStatus(page, limit, status, payment_status)),
  exportOrdersDataHandler: (startDate, endDate, status, payment_status) =>
    dispatch(exportOrdersData(startDate, endDate, status, payment_status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
